import React, { useEffect, useState } from "react";
import {
    ContentUkm,
    ParamsKesimpulan,
    UserResponse,
} from "../../constants/types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import FormControl from "@mui/material/FormControl";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import { BlobProvider } from "@react-pdf/renderer";
import PrintStrukKesimpulan from "../PrintStrukKesimpulan/PrintStrukKesimpulan";
import useKesimpulan from "../../services/queries/useKesimpulan";
import { useAuth } from "../../context/authContext";
import Print from "@mui/icons-material/Print";

registerLocale("id", id);
setDefaultLocale("id");

interface IModalPrintTutupBukuProps {
    user?: UserResponse["data"];
    ukmData?: ContentUkm;
    nowK: string;
    open: boolean;
    onClose: () => void;
}

const ModalPrintTutupBuku = ({
    user,
    ukmData,
    nowK,
    open,
    onClose,
}: IModalPrintTutupBukuProps) => {
    const moment = require("moment");
    let now = moment().format("YYYY/MM/DD");
    const { ukmIdUser } = useAuth();

    const initialParamsKesimpulan = {
        ukmId: undefined,
        tanggal: now,
    };
    const [paramsKesimpulan, setParamsKesimpulan] = useState<ParamsKesimpulan>(
        initialParamsKesimpulan,
    );
    const { data: kesimpulan } = useKesimpulan(paramsKesimpulan);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsKesimpulan((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={open}
            onClose={() => {
                onClose();
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Print Tutup Buku
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack mb={26}>
                    <Typography>Pilih tanggal</Typography>
                    <DatePicker
                        className="range-filter"
                        dateFormat="dd/MM/yyyy"
                        disabledKeyboardNavigation
                        onFocus={(e) => e.target.blur()}
                        selected={moment(paramsKesimpulan.tanggal).toDate()}
                        onChange={(update) => {
                            if (update) {
                                setParamsKesimpulan((prev) => ({
                                    ...prev,
                                    tanggal:
                                        moment(update).format("YYYY/MM/DD"),
                                }));
                            }
                        }}
                        // minDate={now}
                        monthsShown={1}
                        customInput={
                            <FormControl fullWidth>
                                <Stack
                                    display="flex"
                                    justifyContent="space-between"
                                    direction="row"
                                    // width="100%"
                                    bgcolor="#FFFF"
                                    height="40px"
                                    sx={{
                                        border: "1px solid #cbcbcb",
                                        borderRadius: "4px",
                                        alignItems: "center",
                                        padding: 2,
                                        cursor: "pointer",
                                    }}
                                >
                                    <Typography
                                        color={
                                            paramsKesimpulan.tanggal
                                                ? undefined
                                                : "#cbcbcb"
                                        }
                                    >
                                        {paramsKesimpulan.tanggal
                                            ? paramsKesimpulan.tanggal
                                                  .split("/")
                                                  .reverse()
                                                  .join("/")
                                            : "Pilih tanggal"}
                                    </Typography>
                                    <ArrowDropDown
                                        sx={{
                                            marginRight: "-9px",
                                        }}
                                    />
                                </Stack>
                            </FormControl>
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                {ukmData && (
                    <BlobProvider
                        document={
                            <PrintStrukKesimpulan
                                namaToko={ukmData?.namaToko ?? "Toko"}
                                alamatToko={ukmData?.alamatToko ?? "Toko"}
                                telpToko={ukmData?.noTelp ?? "Toko"}
                                gambarToko={
                                    ukmData?.gambarTokoBW === null
                                        ? undefined
                                        : ukmData?.gambarTokoBW
                                }
                                tanggalTransaksi={moment(
                                    paramsKesimpulan.tanggal,
                                ).format("DD MMMM YYYY")}
                                html={undefined}
                                kesimpulan={kesimpulan}
                                tanggalCetak={nowK}
                                user={user}
                            />
                        }
                    >
                        {({ blob, url, loading, error }) => {
                            return (
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition="center"
                                    variant="contained"
                                    href={url ?? ""}
                                    startIcon={<Print />}
                                    size="small"
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={{ width: "fit-content" }}
                                >
                                    Print
                                </LoadingButton>
                            );
                        }}
                    </BlobProvider>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ModalPrintTutupBuku;

import React from "react";
import {
    Card,
    Box,
    Stack,
    Typography,
    TextField,
    Avatar,
    Grid,
    useMediaQuery,
    Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import toRupiah from "../../utils/toRupiah";
import avatarAlt from "../../utils/avatarAlt";
import { ICart } from "../../constants/types";
import "./CardPesan.css";
import truncate from "../../utils/truncate";

interface ICardPesanProps {
    data: ICart;
}

const CardPesan = ({ data }: ICardPesanProps) => {
    const { namaBarang, qty, jumlahStok, harga } = data;
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));

    let valueQty = "0";
    let strQty = String(qty).replace(/^0+/, "");
    if (strQty[0] === "") {
        valueQty = "0";
    } else {
        valueQty = String(qty);
    }

    return (
        <Card
            variant="outlined"
            sx={{
                marginBottom: 1,
                padding: isTabletScreen ? 1 : 2,
            }}
        >
            <Box display="grid" className="card-pesan">
                <Stack
                    direction="row"
                    spacing={isTabletScreen ? 0.5 : 1}
                    alignItems="flex-start"
                    gridColumn={isPhoneScreen ? "1 / 3" : "unset"}
                >
                    <Avatar
                        sx={{
                            display: isTabletScreen ? "none" : undefined,
                            backgroundColor: "primary.main",
                            width: 65,
                            height: 70,
                        }}
                        variant="rounded"
                        alt={namaBarang}
                        src={""}
                    >
                        {avatarAlt(namaBarang)}
                    </Avatar>
                    <Box flexDirection="column" width="100%">
                        <Tooltip title={namaBarang}>
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle2"}
                                fontWeight={isTabletScreen ? 500 : "bold"}
                            >
                                {truncate(namaBarang, 20)}
                            </Typography>
                        </Tooltip>
                        <Grid container my={isTabletScreen ? 0.5 : 1}>
                            <Grid item>
                                <Typography
                                    fontSize={12}
                                    color="#76747C"
                                    fontWeight="bold"
                                >
                                    {`${toRupiah(Number(harga))}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography
                            variant="subtitle2"
                            color="primary"
                            fontWeight="bold"
                        >
                            {toRupiah(Number(harga) * qty)}
                        </Typography>
                    </Box>
                </Stack>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="center"
                >
                    <Stack
                        direction="row"
                        width="100%"
                        spacing={1}
                        gridColumn={isPhoneScreen ? "1/3" : "unset"}
                        display="grid"
                        gridTemplateColumns="auto 1fr auto"
                    >
                        <TextField
                            value={valueQty}
                            type="number"
                            size="small"
                            // sx={{ minWidth: 60, minHeight: 30 }}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {}}
                            inputProps={{
                                style: {
                                    textAlign: "center",
                                    backgroundColor: "white",
                                    fontWeight: "bold",
                                    minWidth: "40px",
                                },
                            }}
                        />
                    </Stack>
                    <Grid mt={1}>
                        <Typography
                            fontSize={10}
                            color="#76747C"
                            fontWeight="bold"
                        >
                            {jumlahStok !== null
                                ? "max. " + jumlahStok
                                : "Tanpa Stok"}
                        </Typography>
                    </Grid>
                </Box>
            </Box>
            {qty > Number(jumlahStok) &&
                jumlahStok !== null &&
                qty !== null && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Typography
                            color="red"
                            variant="subtitle2"
                            fontWeight="bold"
                        >
                            Jumlah pesanan melebihi stok
                        </Typography>
                    </Box>
                )}
        </Card>
    );
};

export default CardPesan;

import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ICart } from "../../constants/types";
import { ReactComponent as BelumAdaPesanan } from "../../assets/images/BelumAdaPesanan.svg";
import CardPesan2 from "../CardPesan/CardPesan2";
import {
    CART_DISPLAY,
    DISPLAY_INFO,
    // defaultColor,
} from "../../constants/common";
import Stack from "@mui/material/Stack";
import toRibuan from "../../utils/toRibuan";
import Grid from "@mui/material/Grid";
import LCM from "../../assets/logo/lcm.png";

interface IDisplayInfo {
    diskon?: number;
    kembalian: number;
    pajak?: number;
    persentaseDiskon?: number | null;
    persentasePajak?: number | null;
    totalTagihan: number;
    uangDibayar: number;
}

const ListPesan2 = () => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));

    const [cart, setCart] = useState<ICart[]>([]);
    const [displayInfo, setDisplayInfo] = useState<IDisplayInfo>({
        diskon: 0,
        kembalian: 0,
        pajak: 0,
        persentaseDiskon: 0,
        persentasePajak: 0,
        totalTagihan: 0,
        uangDibayar: 0,
    });

    window.onstorage = (event) => {
        if (event.key === CART_DISPLAY && event.newValue) {
            const sharedCart: ICart[] = JSON.parse(event.newValue);
            if (cart !== sharedCart) {
                setCart(sharedCart);
            }
        }
        if (event.key === DISPLAY_INFO && event.newValue) {
            const displayInfoShared: IDisplayInfo = JSON.parse(event.newValue);
            if (displayInfo !== displayInfoShared) {
                console.log("saved");
                setDisplayInfo(displayInfoShared);
            }
        }
    };

    const {
        diskon: diskonDisplay,
        kembalian: kembalianDisplay,
        pajak,
        persentaseDiskon,
        persentasePajak,
        totalTagihan,
        uangDibayar,
    } = displayInfo;

    const totalHarga = useMemo(
        () =>
            cart
                .map((item) => item.qty * item.harga)
                .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0,
                ) ?? 0,
        [cart],
    );

    if (cart.length <= 0) {
        return (
            <Box
                display="flex"
                flex={1}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
                // bgcolor={defaultColor}
            >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#fff"
                    borderRadius={2}
                    padding={3}
                >
                    <Box
                        component="img"
                        src={LCM}
                        alt="photo"
                        width={isTabletScreen ? "20%" : "20%"}
                        height="fit-content"
                        sx={{
                            objectFit: "cover",
                        }}
                    />
                    <BelumAdaPesanan
                        width={isTabletScreen ? "20%" : "50%"}
                        style={{ height: "fit-content" }}
                    />

                    <Typography
                        marginTop={isTabletScreen ? 2 : isPhoneScreen ? 1 : 5}
                        variant={
                            isTabletScreen || isPhoneScreen ? "body2" : "h6"
                        }
                        fontWeight="bold"
                    >
                        IKON KULINER KOTA SEMARANG
                    </Typography>
                </Stack>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Box
                display="grid"
                gridTemplateRows="auto 1fr auto"
                padding={2}
                sx={{
                    height: "calc(100vh - 32px)",
                }}
            >
                <Typography
                    variant={isTabletScreen ? "body2" : "h6"}
                    fontSize={isPhoneScreen ? "18px" : undefined}
                    fontWeight="bold"
                >
                    Barang yang Dipesan
                </Typography>
                <Box
                    className="hide-scroll"
                    mt={2}
                    sx={{
                        overflowY: "auto",
                    }}
                >
                    {cart.length > 0 ? (
                        cart.map((li, idx) => (
                            <CardPesan2 key={idx.toString()} data={li} />
                        ))
                    ) : (
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                        >
                            <BelumAdaPesanan
                                width={isTabletScreen ? "20%" : "50%"}
                                style={{ height: "fit-content" }}
                            />
                            <Typography
                                marginTop={
                                    isTabletScreen ? 2 : isPhoneScreen ? 1 : 5
                                }
                                variant={
                                    isTabletScreen || isPhoneScreen
                                        ? "body2"
                                        : "h6"
                                }
                                fontWeight="bold"
                            >
                                IKON KULINER KOTA SEMARANG
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Divider />
                <Grid container justifyContent="space-between" mt={1}>
                    <Grid item xs={6} sm={3} md={4} lg={4}>
                        <Typography
                            variant={isTabletScreen ? "body2" : "subtitle1"}
                            color="#404040"
                        >
                            Subtotal Harga
                        </Typography>
                    </Grid>
                    <Grid item lg={3} xs={5}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            paddingX={1}
                        >
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                fontWeight="bold"
                            >
                                Rp.
                            </Typography>
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                fontWeight="bold"
                            >
                                {toRibuan(totalHarga)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container marginTop={1} justifyContent="space-between">
                    <Grid item xs={6} sm={4} md={5} lg={5}>
                        <Typography
                            variant={isTabletScreen ? "body2" : "subtitle1"}
                            color="#404040"
                        >
                            Diskon{" "}
                            {persentaseDiskon && persentaseDiskon > 0
                                ? `(${persentaseDiskon}%)`
                                : ""}
                        </Typography>
                    </Grid>
                    <Grid item lg={3} xs={5}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            bgcolor="#FFF5EC"
                            paddingX={1}
                        >
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#FB923C"
                                fontWeight="bold"
                            >
                                Rp.
                            </Typography>
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#FB923C"
                                fontWeight="bold"
                            >
                                {toRibuan(diskonDisplay ?? 0)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container marginTop={1} justifyContent="space-between">
                    <Grid item xs={6} sm={4} md={5} lg={5}>
                        <Typography
                            variant={isTabletScreen ? "body2" : "subtitle1"}
                            color="#404040"
                        >
                            Pajak{" "}
                            {persentasePajak && persentasePajak > 0
                                ? `(${persentasePajak}%)`
                                : ""}
                        </Typography>
                    </Grid>
                    <Grid item lg={3} xs={5}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            bgcolor="#FFF5EC"
                            paddingX={1}
                        >
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#FB923C"
                                fontWeight="bold"
                            >
                                Rp.
                            </Typography>
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#FB923C"
                                fontWeight="bold"
                            >
                                {toRibuan(pajak ?? 0)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    marginTop={1}
                    justifyContent="space-between"
                >
                    <Grid item xs={6} sm={3} md={4} lg={4}>
                        <Typography
                            variant={isTabletScreen ? "body2" : "subtitle1"}
                            color="#404040"
                        >
                            Total Tagihan
                        </Typography>
                    </Grid>
                    <Grid item lg={3} xs={5}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            bgcolor="#E8F6ED"
                            paddingX={1}
                        >
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#45A779"
                                fontWeight="bold"
                            >
                                Rp.
                            </Typography>
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#45A779"
                                fontWeight="bold"
                            >
                                {toRibuan(totalTagihan)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    marginTop={1}
                    justifyContent="space-between"
                >
                    <Grid item xs={6} sm={3} md={4} lg={4}>
                        <Typography
                            variant={isTabletScreen ? "body2" : "subtitle1"}
                            color="#404040"
                        >
                            {kembalianDisplay < 0 ? "Dp" : "Uang yang Dibayar"}
                        </Typography>
                    </Grid>
                    <Grid item lg={3} xs={5}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            bgcolor="#FFF5EC"
                            paddingX={1}
                        >
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#FB923C"
                                fontWeight="bold"
                            >
                                Rp.
                            </Typography>
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#FB923C"
                                fontWeight="bold"
                            >
                                {toRibuan(uangDibayar ?? 0)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    marginTop={1}
                    justifyContent="space-between"
                >
                    <Grid item xs={6} sm={3} md={4} lg={4}>
                        <Typography
                            variant={isTabletScreen ? "body2" : "subtitle1"}
                            color="#404040"
                        >
                            {kembalianDisplay < 0 ? "Piutang" : "Kembalian"}
                        </Typography>
                    </Grid>
                    <Grid item lg={3} xs={5}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            bgcolor="#FCEAEA"
                            paddingX={1}
                        >
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#DC2626"
                                fontWeight="bold"
                            >
                                Rp.
                            </Typography>
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle1"}
                                color="#DC2626"
                                fontWeight="bold"
                            >
                                {toRibuan(kembalianDisplay)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default ListPesan2;

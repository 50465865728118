import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import { BlobProvider } from "@react-pdf/renderer";
import React, { useState } from "react";
import { GetDetailInvoiceResponse } from "../../constants/types";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import toRupiah from "../../utils/toRupiah";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Tooltip } from "@mui/material";
import truncate from "../../utils/truncate";
import PrintDapur from "../PrintStruk/PrintDapur";
import moment from "moment";

interface IModalDetailInvoiceProps {
    data: GetDetailInvoiceResponse["data"];
    jatuhTempo: string | null;
    fullScreen: boolean;
    isOpenModalDetailInvoice: boolean;
    statusDetailInvoice: "idle" | "error" | "loading" | "success";
    closeModalDetailInvoice: () => void;
    dp?: number | null;
    isPiutang: number;
}

const ModalDetailInvoice = ({
    fullScreen,
    isOpenModalDetailInvoice,
    statusDetailInvoice,
    closeModalDetailInvoice,
    isPiutang,
    dp,
    jatuhTempo,
    data,
}: IModalDetailInvoiceProps) => {
    const {
        tanggalTransaksi,
        nomorInvoice,
        daftarItem,
        totalHarga,
        diskon,
        pajak,
        totalPembayaran,
        uangYangDibayar,
        kembalian,
        kasir,
        sales,
        metode: namaMetode,
        keterangan,
        keteranganLain: keterangan2,
        encrypt,
        metodeArr,
        pelanggan,
    } = data;
    const { data: user } = useUser();
    const pDapur = React.useRef<any>(null);
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const newCart = daftarItem.map((items, index) => ({
        id: index,
        namaBarang: String(items.namaItem),
        qty: Number(items.jumlahItem),
        harga: Number(items.hargaSatuan),
    }));

    const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailInvoice && statusDetailInvoice === "success"}
            onClose={() => {
                setKirimEmail(false);
                setEmail("");
                closeModalDetailInvoice();
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            setKirimEmail(false);
                            setEmail("");
                            closeModalDetailInvoice();
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Invoice
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            Tanggal
                        </Typography>
                        <Typography variant="subtitle2">
                            {/* Hide waktu pada tanggal transaksi */}
                            {tanggalTransaksi}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            No. Invoice
                        </Typography>
                        <Typography variant="subtitle2">
                            {nomorInvoice}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            Metode
                        </Typography>
                        <Typography variant="subtitle2">
                            {namaMetode ?? kembalian < 0 ? "Piutang" : "Tunai"}
                        </Typography>
                    </Grid>
                </Grid>
                <Box my={2}>
                    <Divider />
                </Box>
                <Stack spacing={1}>
                    {daftarItem.map((items, index) => (
                        <Stack direction="row" key={index}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Tooltip title={items.namaItem}>
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                        >
                                            {truncate(items.namaItem, 20)}
                                        </Typography>
                                    </Tooltip>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        {toRupiah(items.hargaSatuan)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        {items.jumlahItem}x
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        textAlign="end"
                                    >
                                        {toRupiah(
                                            items.hargaSatuan *
                                                items.jumlahItem,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    ))}
                </Stack>
                <Box my={2}>
                    <Divider />
                </Box>
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(totalHarga)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Diskon
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(diskon)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Pajak
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(pajak ?? 0)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    my={2}
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Total</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(totalPembayaran)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                {isPiutang === 1
                    ? dp && (
                          <Stack
                              direction="row"
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                          >
                              <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="flex-end"
                              >
                                  <Grid item xs={3}>
                                      <Typography
                                          variant="subtitle2"
                                          color="#A1A1AA"
                                      >
                                          {"Dp"}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                      <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                          textAlign="end"
                                      >
                                          {toRupiah(dp)}
                                      </Typography>
                                  </Grid>
                              </Grid>
                          </Stack>
                      )
                    : null}
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                {"Uang yang Dibayar"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(uangYangDibayar)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                {totalPembayaran > uangYangDibayar
                                    ? "Piutang"
                                    : "Kembalian"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(
                                    kembalian < 0
                                        ? (totalPembayaran - uangYangDibayar) *
                                              -1
                                        : kembalian,
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                {pelanggan.nama && (
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    Pelanggan
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {pelanggan.nama}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        alignItems="center"
                        // justifyContent="flex-end"
                    >
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}>
                            <Typography
                                variant="subtitle2"
                                color="#A1A1AA"
                                mb={1}
                            >
                                Keterangan
                            </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                            <TextField
                                disabled
                                multiline
                                rows={2}
                                fullWidth
                                value={keterangan}
                                sx={{
                                    "& .MuiOutlinedInput-input.Mui-disabled": {
                                        color: "rgba(0, 0, 0, 0.87)",
                                        WebkitTextFillColor:
                                            "rgba(0, 0, 0, 0.87)",
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    mb={5}
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        alignItems="center"
                        // justifyContent="flex-end"
                    >
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}>
                            <Typography
                                variant="subtitle2"
                                color="#A1A1AA"
                                mb={1}
                            >
                                Keterangan 2
                            </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                            <TextField
                                disabled
                                multiline
                                rows={2}
                                fullWidth
                                value={keterangan2}
                                sx={{
                                    "& .MuiOutlinedInput-input.Mui-disabled": {
                                        color: "rgba(0, 0, 0, 0.87)",
                                        WebkitTextFillColor:
                                            "rgba(0, 0, 0, 0.87)",
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
                <Box
                    display="flex"
                    // justifyContent="flex-start"
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={kirimEmail}
                                onChange={(_, checked) => {
                                    handleKirimEmail(checked);
                                }}
                            />
                        }
                        label="Kirim struk"
                        sx={{
                            height: "fit-content",
                        }}
                    />
                </Box>
                {kirimEmail && (
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        marginBottom={2}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={email}
                                size="small"
                                type="email"
                                fullWidth
                                placeholder="8316352725"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +62
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => setEmail(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                disabled={email.length <= 0}
                                variant="outlined"
                                onClick={() => {
                                    setKirimEmail(false);
                                    setEmail("");
                                    closeModalDetailInvoice();
                                }}
                                href={`https://api.whatsapp.com/send/?phone=62${email}&text=Struk%20pembelian%20di%20LCM%20%28Lunpia%20Cik%20Me%20Me%29%20total%20${toRupiah(
                                    totalPembayaran,
                                )}%0A%0ALihat%20detail%20di%20${
                                    window.location.origin
                                }/struk/${encrypt}`}
                                target="_blank"
                                rel="noreferrer"
                                fullWidth
                            >
                                Kirim struk ke WhatsApp
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <Stack
                    width="100%"
                    direction="column"
                    alignItems="center"
                    spacing={1}
                >
                    <Button
                        variant="text"
                        onClick={() => {
                            setKirimEmail(false);
                            setEmail("");
                            closeModalDetailInvoice();
                        }}
                        fullWidth
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        Kembali
                    </Button>
                    <Box width="100%">
                        {ukmData && (
                            <BlobProvider
                                document={
                                    <PrintStrukPdf
                                        dp={dp}
                                        jatuhTempo={
                                            jatuhTempo &&
                                            moment(jatuhTempo).format(
                                                "DD MMM YYYY",
                                            )
                                        }
                                        namaToko={ukmData.namaToko}
                                        alamatToko={ukmData.alamatToko}
                                        telpToko={ukmData.noTelp}
                                        telpTokoArr={ukmData?.noTelpArr}
                                        gambarToko={
                                            ukmData?.gambarTokoBW === null
                                                ? undefined
                                                : ukmData?.gambarTokoBW
                                        }
                                        tanggalTransaksi={tanggalTransaksi}
                                        kasir={kasir}
                                        sales={sales}
                                        total={totalPembayaran}
                                        cart={newCart}
                                        diskon={diskon}
                                        pajak={pajak}
                                        tunai={uangYangDibayar}
                                        kembalian={
                                            kembalian < 0
                                                ? (totalPembayaran -
                                                      uangYangDibayar) *
                                                  -1
                                                : kembalian
                                        }
                                        isPro={ukmData.isPro}
                                        namaMetode={
                                            namaMetode ?? kembalian < 0
                                                ? "Piutang"
                                                : "Tunai"
                                        }
                                        html={
                                            findCustomStruk &&
                                            findCustomStruk.html !== null
                                                ? findCustomStruk?.html
                                                : undefined
                                        }
                                        keterangan={keterangan}
                                        keterangan2={keterangan2}
                                        noMetode={metodeArr.noTujuan}
                                        namaPelanggan={pelanggan.nama ?? ""}
                                        noInvoice={nomorInvoice}
                                    />
                                }
                            >
                                {({ blob, url, loading, error }) => {
                                    return (
                                        <LoadingButton
                                            loading={loading}
                                            loadingPosition="center"
                                            fullWidth
                                            variant="contained"
                                            href={url ?? ""}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Print Ulang Struk
                                        </LoadingButton>
                                    );
                                }}
                            </BlobProvider>
                        )}
                    </Box>
                    <Box width="100%">
                        {ukmData && (
                            <BlobProvider
                                document={
                                    <PrintStrukPdf
                                        isIncludeDapur={true}
                                        dp={dp}
                                        jatuhTempo={
                                            jatuhTempo &&
                                            moment(jatuhTempo).format(
                                                "DD MMM YYYY",
                                            )
                                        }
                                        namaToko={ukmData.namaToko}
                                        alamatToko={ukmData.alamatToko}
                                        telpToko={ukmData.noTelp}
                                        telpTokoArr={ukmData?.noTelpArr}
                                        gambarToko={
                                            ukmData?.gambarTokoBW === null
                                                ? undefined
                                                : ukmData?.gambarTokoBW
                                        }
                                        tanggalTransaksi={tanggalTransaksi}
                                        kasir={kasir}
                                        sales={sales}
                                        total={totalPembayaran}
                                        cart={newCart}
                                        diskon={diskon}
                                        pajak={pajak}
                                        tunai={uangYangDibayar}
                                        kembalian={
                                            kembalian < 0
                                                ? (totalPembayaran -
                                                      uangYangDibayar) *
                                                  -1
                                                : kembalian
                                        }
                                        isPro={ukmData.isPro}
                                        namaMetode={
                                            namaMetode ?? kembalian < 0
                                                ? "Piutang"
                                                : "Tunai"
                                        }
                                        html={
                                            findCustomStruk &&
                                            findCustomStruk.html !== null
                                                ? findCustomStruk?.html
                                                : undefined
                                        }
                                        keterangan={keterangan}
                                        keterangan2={keterangan2}
                                        noMetode={metodeArr.noTujuan}
                                        namaPelanggan={pelanggan.nama ?? ""}
                                        noInvoice={nomorInvoice}
                                    />
                                }
                            >
                                {({ blob, url, loading, error }) => {
                                    return (
                                        <LoadingButton
                                            loading={loading}
                                            loadingPosition="center"
                                            fullWidth
                                            variant="outlined"
                                            href={url ?? ""}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Print Ulang Struk + Dapur
                                        </LoadingButton>
                                    );
                                }}
                            </BlobProvider>
                        )}
                    </Box>
                    <Box width="100%">
                        {ukmData && (
                            <BlobProvider
                                document={
                                    <PrintDapur
                                        namaToko={ukmData.namaToko}
                                        alamatToko={ukmData.alamatToko}
                                        telpToko={ukmData.noTelp}
                                        gambarToko={
                                            ukmData?.gambarTokoBW === null
                                                ? undefined
                                                : ukmData?.gambarTokoBW
                                        }
                                        tanggalTransaksi={tanggalTransaksi}
                                        kasir={kasir}
                                        sales={sales}
                                        total={totalPembayaran}
                                        cart={newCart}
                                        diskon={diskon}
                                        pajak={pajak}
                                        tunai={uangYangDibayar}
                                        kembalian={
                                            kembalian < 0
                                                ? (totalPembayaran -
                                                      uangYangDibayar) *
                                                  -1
                                                : kembalian
                                        }
                                        isPro={ukmData.isPro}
                                        namaMetode={
                                            namaMetode ?? kembalian < 0
                                                ? "Piutang"
                                                : "Tunai"
                                        }
                                        html={
                                            findCustomStruk &&
                                            findCustomStruk.html !== null
                                                ? findCustomStruk?.html
                                                : undefined
                                        }
                                        keterangan={keterangan}
                                        noMetode={metodeArr.noTujuan}
                                        namaPelanggan={pelanggan.nama ?? ""}
                                    />
                                }
                            >
                                {({ blob, url, loading, error }) => {
                                    return (
                                        <LoadingButton
                                            style={{
                                                display: "none",
                                            }}
                                            ref={pDapur}
                                            loading={loading}
                                            loadingPosition="center"
                                            fullWidth
                                            variant="outlined"
                                            href={url ?? ""}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Print Dapur
                                        </LoadingButton>
                                    );
                                }}
                            </BlobProvider>
                        )}
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailInvoice;

import React, { memo } from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
} from "@react-pdf/renderer";
import toRibuan from "../../utils/toRibuan";
import OpenSans from "../../assets/fonts/OpenSans-Regular.ttf";
import OpenSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import OpenSansSemiBold from "../../assets/fonts/OpenSans-SemiBold.ttf";
import OpenSansMedium from "../../assets/fonts/OpenSans-Medium.ttf";
import UbuntuMono from "../../assets/fonts/UbuntuMono-Regular.ttf";
import { GetKesimpulanResponse, UserResponse } from "../../constants/types";

Font.register({
    family: "OpenSans",
    format: "truetype",
    src: OpenSans,
});
Font.register({
    family: "OpenSansBold",
    format: "truetype",
    src: OpenSansBold,
});
Font.register({
    family: "OpenSansSemiBold",
    format: "truetype",
    src: OpenSansSemiBold,
});
Font.register({
    family: "OpenSansMedium",
    format: "truetype",
    src: OpenSansMedium,
});
Font.register({
    family: "UbuntuMono",
    format: "truetype",
    src: UbuntuMono,
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 14,
        paddingRight: 14,
        height: "100%",
    },
    containerTitle: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 12,
    },
    title: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 10,
        marginTop: 0,
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        fontFamily: "OpenSansSemiBold",
        fontWeight: "bold",
    },
    subtitle: {
        fontSize: 8,
        marginTop: 0,
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        fontFamily: "OpenSans",
    },
    cashierName: {
        fontSize: 8,
        marginTop: 6,
        fontFamily: "OpenSans",
    },
    tanggalTransaksi: {
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 8,
        fontFamily: "OpenSans",
    },
    containerHeader: {
        flexDirection: "row",
        backgroundColor: "#45A779",
        color: "#fff",
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontStyle: "bold",
        flexGrow: 1,
    },
    namaHeader: {
        width: "45%",
        fontSize: 14,
        fontWeight: 700,
    },
    hargaHeader: {
        width: "15%",
        fontSize: 14,
        fontWeight: 700,
    },
    jumlahHeader: {
        width: "10%",
        fontSize: 14,
        fontWeight: 700,
    },
    skuHeader: {
        width: "25%",
        fontSize: 14,
        fontWeight: 700,
    },
    row: {
        fontSize: 8,
        flexDirection: "row",
        fontFamily: "OpenSans",
    },
    column: {
        marginTop: 8,
        marginBottom: 4,
        fontSize: 8,
        flexDirection: "column",
        fontFamily: "OpenSans",
    },
    containerNamaBarang: {
        width: "100%",
        flexDirection: "column",
    },
    namaBarang: {
        textAlign: "left",
        fontSize: 8,
        marginBottom: 1,
        fontFamily: "OpenSansSemiBold",
    },
    qty: {
        width: "15%",
        textAlign: "left",
        fontSize: 8,
    },
    hargaSatuan: {
        width: "40%",
        fontSize: 8,
        textAlign: "left",
    },
    rate: {
        width: "45%",
        textAlign: "right",
        fontSize: 8,
    },
    keterangan: {
        fontSize: 8,
        marginTop: 8,
        fontFamily: "OpenSans",
    },
    divider: {
        marginVertical: 4,
        borderTop: "1px",
        color: "black",
        borderStyle: "dashed",
    },
    containerInfoTransaksi: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontFamily: "OpenSans",
    },
    containerTransaksiTotal: {
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 8,
        width: "100%",
        fontFamily: "OpenSans",
    },
    containerTransaksiTotalHarga: {
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "27%",
        fontSize: 8,
        fontFamily: "OpenSans",
        textAlign: "right",
    },
    containerTransaksi: {
        flexDirection: "row",
        justifyContent: "flex-start",
        width: "63%",
        fontSize: 8,
        fontFamily: "OpenSans",
    },
    containerTransaksiQty: {
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "10%",
        fontSize: 8,
        fontFamily: "OpenSans",
        textAlign: "right",
    },
    containerTransaksiM: {
        flexDirection: "row",
        justifyContent: "center",
        fontSize: 8,
        fontFamily: "OpenSans",
    },
    infoTransaksi: {
        fontSize: 8,
        width: "50%",
        textAlign: "left",
    },
    nilaiTransaksi: {
        width: "50%",
        fontSize: 8,
        textAlign: "right",
    },
    infoTransaksiBold: {
        fontSize: 8,
        fontFamily: "OpenSansSemiBold",
        width: "40%",
        textAlign: "left",
    },
    nilaiTransaksiBold: {
        width: "50%",
        fontSize: 8,
        fontFamily: "OpenSansSemiBold",
        textAlign: "right",
    },
    containerPenutup: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 8,
    },
    containerRingkasan: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        fontFamily: "OpenSans",
        fontSize: 8,
        marginBottom: 150,
    },
    containerBelumLunas: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 8,
        fontFamily: "OpenSans",
    },
    penutup: {
        fontSize: 8,
        fontFamily: "OpenSansSemiBold",
    },
    containerWaterMark: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 6,
        marginTop: 10,
        marginBottom: 5,
        fontFamily: "OpenSans",
    },
    containerNore: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 6,
    },
    containerInvoicePiutang: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 8,
        fontWeight: "bold",
        marginBottom: 2,
    },
    containerLogo: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 12,
        marginBottom: 6,
    },
    logo: {
        width: 54,
        height: 54,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    qr: {
        width: "3cm",
        height: "3cm",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    boldText: {
        fontWeight: 700,
        fontFamily: "OpenSansSemiBold",
    },
    containerJatuhTempo: {
        width: "100%",
        marginTop: 10,
        marginBottom: 14,
    },
});

interface IPrintStrukProps {
    namaToko: string;
    gambarToko?: string;
    alamatToko: string;
    telpToko: string;
    tanggalTransaksi: string;
    tanggalCetak: string;
    html?: any;
    kesimpulan?: GetKesimpulanResponse["data"];
    user?: UserResponse["data"];
}

const PrintStrukKesimpulan = (props: IPrintStrukProps) => {
    // const rows = props.kesimpulan.items
    //     ? props.kesimpulan.items.map((row: any, idx: number) => (
    //           <View style={styles.containerTransaksi} key={idx}>
    //               <Text>{row.jumlah} </Text>
    //               <Text>{row.nama}</Text>
    //           </View>
    //       ))
    //     : null;

    const rows = props.kesimpulan?.items
        ? props.kesimpulan.items.map((row: any, idx: number) => (
              <View style={styles.containerTransaksiTotal} key={idx}>
                  <View style={styles.containerTransaksi}>
                      <Text>{row.nama}</Text>
                  </View>
                  <View style={styles.containerTransaksiQty}>
                      <Text>{row.jumlah}</Text>
                  </View>
                  <View style={styles.containerTransaksiTotalHarga}>
                      <Text>{toRibuan(String(row.totalHarga))}</Text>
                  </View>
              </View>
          ))
        : null;

    // const rowp = props.kesimpulan?.itemPiutangs
    //     ? props.kesimpulan.itemPiutangs.map((row: any, idx: number) => (
    //           <View style={styles.containerTransaksiTotal} key={idx}>
    //               <View style={styles.containerTransaksi}>
    //                   <Text>{row.nama}</Text>
    //               </View>
    //               <View style={styles.containerTransaksiQty}>
    //                   <Text>{row.jumlah}</Text>
    //               </View>
    //               <View style={styles.containerTransaksiTotalHarga}>
    //                   <Text>{toRibuan(String(row.totalHarga))}</Text>
    //               </View>
    //           </View>
    //       ))
    //     : null;

    const rowm =
        props.kesimpulan?.metodePembayarans &&
        props.kesimpulan.metodePembayarans.find((li) => li.nama === "Tunai") ? (
            <View style={styles.containerTransaksiTotal}>
                <View style={styles.containerTransaksi}>
                    <Text>Subtotal</Text>
                </View>
                <View style={styles.containerTransaksiQty}></View>
                <View style={styles.containerTransaksiTotalHarga}>
                    <Text>
                        {toRibuan(
                            String(
                                Number(
                                    props.kesimpulan?.metodePembayarans.find(
                                        (li) => li.nama === "Tunai",
                                    )?.total,
                                ) + Number(props.kesimpulan?.totalDiskon),
                            ),
                        )}
                    </Text>
                </View>
            </View>
        ) : null;

    const totalDiskon =
        props.kesimpulan?.totalDiskon && props.kesimpulan.totalDiskon > 0 ? (
            <View style={styles.containerTransaksiTotal}>
                <View style={styles.containerTransaksi}>
                    <Text>Total Diskon</Text>
                </View>
                <View style={styles.containerTransaksiQty}></View>
                <View style={styles.containerTransaksiTotalHarga}>
                    <Text>{toRibuan(props.kesimpulan.totalDiskon)}</Text>
                </View>
            </View>
        ) : null;

    const grandTotal = (
        <View style={styles.containerTransaksiTotal}>
            <View style={styles.containerTransaksi}>
                <Text>Grand Total</Text>
            </View>
            <View style={styles.containerTransaksiQty}></View>
            <View style={styles.containerTransaksiTotalHarga}>
                <Text>
                    {toRibuan(
                        String(
                            Number(
                                props.kesimpulan?.metodePembayarans.find(
                                    (li) => li.nama === "Tunai",
                                )?.total,
                            ),
                        ),
                    )}
                </Text>
            </View>
        </View>
    );

    console.log("kesimpulan", props.kesimpulan);
    return (
        <Document>
            <Page
                wrap
                size={{ width: "7.5cm", height: "100%" }}
                style={styles.page}
            >
                <View>
                    <View style={styles.tanggalTransaksi}>
                        <Text>LAPORAN PENJUALAN</Text>
                        <Text>{props.tanggalTransaksi}</Text>
                    </View>

                    {/* <View style={styles.divider}></View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Transaksi : </Text>
                        <Text>{props.kesimpulan.totalTransaksi ?? 0}</Text>
                    </View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Item Terjual : </Text>
                        <Text>{props.kesimpulan.totalItemTerjual ?? 0}</Text>
                    </View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Pajak : Rp</Text>
                        <Text>
                            {toRibuan(props.kesimpulan.totalPajak ?? 0)}
                        </Text>
                    </View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Gross : Rp</Text>
                        <Text>
                            {toRibuan(props.kesimpulan.totalGross ?? 0)}
                        </Text>
                    </View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Dp : Rp</Text>
                        <Text>{toRibuan(props.kesimpulan.totalDp ?? 0)}</Text>
                    </View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Diskon : Rp</Text>
                        <Text>
                            {toRibuan(props.kesimpulan.totalDiskon ?? 0)}
                        </Text>
                    </View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Item Void : </Text>
                        <Text>{props.kesimpulan.totalItemVoid ?? 0}</Text>
                    </View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Void : </Text>
                        <Text>{props.kesimpulan.totalVoid ?? 0}</Text>
                    </View>
                    <View style={styles.containerTransaksi}>
                        <Text>Total Net : Rp</Text>
                        <Text>{toRibuan(props.kesimpulan.totalNet ?? 0)}</Text>
                    </View> */}
                    {props.kesimpulan?.items &&
                        props.kesimpulan.items.length > 0 && (
                            <>
                                <View style={styles.divider}></View>
                                {/* <View style={styles.containerInvoicePiutang}>
                                    <Text>Invoice</Text>
                                </View> */}
                            </>
                        )}
                    <View>{rows}</View>
                    {/* {props.kesimpulan.itemPiutangs &&
                        props.kesimpulan.itemPiutangs.length > 0 && (
                            <>
                                <View style={styles.divider}></View>
                                <View style={styles.containerInvoicePiutang}>
                                    <Text>Piutang</Text>
                                </View>
                            </>
                        )}
                    <View>{rowp}</View> */}
                    {props.kesimpulan?.metodePembayarans &&
                        props.kesimpulan.metodePembayarans.length > 0 && (
                            <>
                                <View style={styles.divider}></View>
                                {/* <View style={styles.containerPenutup}>
                                    <Text>Rincian tipe pembayaran</Text>
                                    </View> */}
                            </>
                        )}
                    <View>{rowm}</View>
                    <View>{totalDiskon}</View>
                    {props.kesimpulan?.totalDiskon ? (
                        <>
                            <View style={styles.divider}></View>
                            <View>{grandTotal}</View>
                        </>
                    ) : null}
                    <View style={styles.divider}></View>
                    <View style={styles.containerRingkasan}>
                        <Text>Tanggal Cetak: {props.tanggalCetak}</Text>
                        <Text>Oleh: {props.user?.namaLengkap}</Text>
                    </View>
                    {/* <View style={styles.containerWaterMark}>
                        <Text>POS System by UKM Lokal</Text>
                    </View> */}
                </View>
            </Page>
        </Document>
    );
};

export default memo(PrintStrukKesimpulan);

import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import toRupiah from "../../utils/toRupiah";
import toRibuan from "../../utils/toRibuan";

// Font.register({
//     family: "Helvetica",
//     format: "truetype",
//     src: Helvetica,
// });
// Font.register({
//     family: "HelveticaBold",
//     format: "truetype",
//     src: HelveticaBold,
// });
// Font.register({
//     family: "Helvetica-Bold",
//     format: "truetype",
//     src: Helvetica-Bold,
// });
// Font.register({
//     family: "Helvetica-Bold",
//     format: "truetype",
//     src: Helvetica-Bold,
// });
// Font.register({
//     family: "UbuntuMono",
//     format: "truetype",
//     src: UbuntuMono,
// });

interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukProps {
    namaToko: string;
    gambarToko?: string;
    alamatToko: string;
    telpToko: string;
    telpTokoArr?: string[];
    total: number;
    dp?: number | null;
    kasir: string;
    sales: string | null;
    tanggalTransaksi: string;
    cart: ICart[] | undefined;
    diskon?: number;
    pajak?: number;
    tunai?: number;
    kembalian?: number;
    isPro: number;
    namaMetode?: string | null;
    noMetode?: string | null;
    belumLunas?: boolean;
    qr?: string | null;
    html?: any;
    keterangan?: string | null;
    keterangan2?: string | null;
    namaPelanggan?: string;
    jatuhTempo: string | null;
    noInvoice: string;
    isIncludeDapur?: boolean;
}

const PrintStrukPdf = (props: IPrintStrukProps) => {
    const isExtraBigSpacing = props.cart?.length === 1 && !props.keterangan;
    const isBigSpacing = props.cart?.length === 1 && props.keterangan;
    const isMediumSpacing =
        props.cart && props.cart?.length > 1 && props.cart?.length < 6;
    const isSmallSpacing =
        props.cart && props.cart?.length > 5 && props.cart?.length < 11;

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#FFF",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 14,
            paddingRight: 14,
            height: "100%",
        },
        containerTitle: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            // marginVertical: 12,
        },
        title: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 12,
            marginTop: 0,
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            fontFamily: "Helvetica-Bold",
            fontWeight: "bold",
        },
        subtitle: {
            fontSize: 10,
            marginTop: 0,
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            fontFamily: "Helvetica",
        },
        cashierName: {
            fontSize: 10,
            marginTop: 6,
            fontFamily: "Helvetica",
        },
        tanggalTransaksi: {
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 10,
            fontFamily: "Helvetica",
        },
        containerHeader: {
            flexDirection: "row",
            backgroundColor: "#45A779",
            color: "#fff",
            alignItems: "center",
            height: 24,
            textAlign: "center",
            fontStyle: "bold",
            flexGrow: 1,
        },
        namaHeader: {
            width: "45%",
            fontSize: 16,
            fontWeight: 700,
        },
        hargaHeader: {
            width: "15%",
            fontSize: 16,
            fontWeight: 700,
        },
        jumlahHeader: {
            width: "10%",
            fontSize: 16,
            fontWeight: 700,
        },
        skuHeader: {
            width: "25%",
            fontSize: 16,
            fontWeight: 700,
        },
        row: {
            fontSize: 10,
            flexDirection: "row",
            fontFamily: "Helvetica",
        },
        column: {
            // marginTop: 8,
            // marginBottom: 4,
            fontSize: 10,
            flexDirection: "column",
            fontFamily: "Helvetica",
        },
        containerNamaBarang: {
            width: "100%",
            flexDirection: "column",
        },
        namaBarang: {
            textAlign: "left",
            fontSize: 10,
            marginBottom: 1,
            fontFamily: "Helvetica-Bold",
        },
        qty: {
            width: "15%",
            textAlign: "left",
            fontSize: 10,
        },
        hargaSatuan: {
            width: "40%",
            fontSize: 10,
            textAlign: "left",
        },
        rate: {
            width: "45%",
            textAlign: "right",
            fontSize: 10,
        },
        keterangan: {
            fontSize: 10,
            // marginTop: 8,
            fontFamily: "Helvetica",
        },
        divider: {
            marginVertical: 4,
            borderTop: "1px",
            color: "black",
            borderStyle: "dashed",
        },
        containerInfoTransaksi: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontFamily: "Helvetica",
        },
        infoTransaksi: {
            fontSize: 10,
            width: "50%",
            textAlign: "left",
        },
        nilaiTransaksi: {
            width: "50%",
            fontSize: 10,
            textAlign: "right",
        },
        infoTransaksiBold: {
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            width: "40%",
            textAlign: "left",
        },
        nilaiTransaksiBold: {
            width: "50%",
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            textAlign: "right",
        },
        containerPenutup: {
            marginTop: 8,
            marginBottom: isExtraBigSpacing ? 100 : 14,
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
        },
        containerBelumLunas: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 10,
            fontFamily: "Helvetica",
        },
        penutup: {
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
        },
        containerWaterMark: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 6,
            marginTop: isExtraBigSpacing
                ? 500
                : isBigSpacing
                ? 400
                : isMediumSpacing
                ? 300
                : isSmallSpacing
                ? 260
                : 5,
            marginBottom: 5,
            fontFamily: "Helvetica",
        },
        containerNore: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 8,
        },
        containerLogo: {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 12,
            marginBottom: 6,
        },
        logo: {
            width: 54,
            height: 54,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
        },
        qr: {
            width: "3cm",
            height: "3cm",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
        },
        boldText: {
            fontWeight: 700,
            fontFamily: "Helvetica-Bold",
        },
        containerJatuhTempo: {
            width: "100%",
            marginTop: 10,
            marginBottom: 14,
        },
        containerDapur: {
            width: "100%",
            // marginTop: 10,
            // marginBottom: 14,
        },
    });

    const stylesheet = {
        // clear margins for all <p> tags
        p: {
            margin: 0,
            textAlign: "center",
        },
    };

    const rows =
        props.cart &&
        props.cart.map((row) => (
            <View style={styles.column} key={row.id}>
                <View style={{ width: "65%", flexDirection: "column" }}>
                    <Text style={styles.namaBarang}>{row.namaBarang}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                    <Text style={styles.qty}>{row.qty}x</Text>
                    <Text style={styles.hargaSatuan}>
                        {toRibuan(row.harga)}
                    </Text>
                    <Text style={styles.rate}>
                        {toRibuan(row.harga * row.qty)}
                    </Text>
                </View>
            </View>
        ));
    const rowd =
        props.cart &&
        props.cart.map((row) => (
            <View style={styles.column} key={row.id}>
                <View style={{ width: "100%", flexDirection: "column" }}>
                    <Text style={styles.namaBarang}>
                        {row.qty}x {row.namaBarang}
                    </Text>
                </View>
            </View>
        ));
    const totalQty = props.cart
        ?.map((df) => df.qty)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (
        <Document>
            {props.isIncludeDapur ? (
                <Page
                    wrap
                    // size={{ width: "5.5cm", height: "100%" }}
                    size={{ width: "7.5cm", height: "100%" }}
                    style={styles.page}
                >
                    <View>
                        {/* {props.gambarToko ? (
                        <View style={styles.containerLogo}>
                            <Image style={styles.logo} src={props.gambarToko} />
                        </View>
                    ) : (
                        <View></View>
                    )} */}
                        {/* <View style={styles.containerTitle}>
                            <View style={styles.title}>
                                <Text>{props.namaToko}</Text>
                            </View>
                            <View style={styles.subtitle}>
                                <Text>{props.alamatToko}</Text>
                            </View>
                            <View style={styles.subtitle}>
                                <Text>{props.telpToko}</Text>
                            </View>
                        </View>
                        {props.qr ? (
                            <View style={styles.containerLogo}>
                                <Image style={styles.qr} src={props.qr} />
                            </View>
                        ) : (
                            <View></View>
                        )} */}
                        <View style={styles.containerDapur}>
                            <View
                                style={{
                                    textAlign: "center",
                                    fontFamily: "Helvetica-Bold",
                                    fontSize: 12,
                                    // marginTop: 16,
                                }}
                            >
                                <Text>DAPUR</Text>
                            </View>
                        </View>
                        <View style={styles.tanggalTransaksi}>
                            <Text>No. Invoice</Text>
                            <Text>{props.noInvoice}</Text>
                        </View>
                        {/* <View style={styles.tanggalTransaksi}>
                            <Text>Kasir</Text>
                            <Text>{props.kasir}</Text>
                        </View>
                        {props.sales !== null ? (
                            <View style={styles.tanggalTransaksi}>
                                <Text>Sales</Text>
                                <Text>{props.sales}</Text>
                            </View>
                        ) : (
                            <View></View>
                        )} */}
                        <View style={styles.tanggalTransaksi}>
                            <Text>Tanggal</Text>
                            <Text>{props.tanggalTransaksi}</Text>
                        </View>
                        {/* {props.namaMetode ? (
                            <View style={styles.tanggalTransaksi}>
                                <Text>Metode</Text>
                                <Text>{props.namaMetode}</Text>
                            </View>
                        ) : (
                            <View></View>
                        )} */}
                        {/* {props.noMetode ? (
                            <View style={styles.tanggalTransaksi}>
                                <Text>No. Tujuan</Text>
                                <Text>{props.noMetode}</Text>
                            </View>
                        ) : (
                            <View></View>
                        )} */}
                        {/* {props.namaPelanggan ? (
                            <View style={styles.tanggalTransaksi}>
                                <Text>Pelanggan</Text>
                                <Text>{props.namaPelanggan}</Text>
                            </View>
                        ) : (
                            <View></View>
                        )} */}
                        {/* <View style={{ marginTop: 12 }}></View> */}
                        <View style={styles.divider}></View>
                        <View style={{ marginVertical: 4 }}>{rowd}</View>
                        <View style={styles.divider}></View>
                        <View
                            style={{
                                width: "100%",
                                flexDirection: "column",
                                // marginVertical: 8,
                            }}
                        >
                            <Text style={styles.namaBarang}>
                                Total Qty : {totalQty} pcs
                            </Text>
                        </View>
                        <View style={styles.divider}></View>
                        {props.keterangan ? (
                            <View style={styles.keterangan}>
                                <Text>Keterangan{"  "}:</Text>
                                <Text>{props.keterangan}</Text>
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {/* <View style={styles.containerPenutup}></View> */}
                        <View style={styles.containerWaterMark}></View>
                    </View>
                </Page>
            ) : null}
            <Page
                wrap
                // size={{ width: "5.5cm", height: "100%" }}
                size={{ width: "7.5cm", height: "100%" }}
                style={styles.page}
            >
                <View>
                    {/* {props.gambarToko ? (
                        <View style={styles.containerLogo}>
                            <Image style={styles.logo} src={props.gambarToko} />
                        </View>
                    ) : (
                        <View></View>
                    )} */}
                    <View style={styles.containerTitle}>
                        <View style={styles.title}>
                            <Text>{props.namaToko}</Text>
                        </View>
                        <View style={styles.subtitle}>
                            <Text>{props.alamatToko}</Text>
                        </View>
                        {props.telpTokoArr && props.telpTokoArr.length > 0 ? (
                            props.telpTokoArr.map((li, idx) => (
                                <View
                                    key={idx.toString()}
                                    style={styles.subtitle}
                                >
                                    <Text>{li}</Text>
                                </View>
                            ))
                        ) : (
                            <View style={styles.subtitle}>
                                <Text>{props.telpToko}</Text>
                            </View>
                        )}
                    </View>
                    {/* {props.qr ? (
                        <View style={styles.containerLogo}>
                            <Image style={styles.qr} src={props.qr} />
                        </View>
                    ) : (
                        <View></View>
                    )} */}
                    {/* {props.jatuhTempo ? (
                        <View style={styles.containerJatuhTempo}>
                            <View
                                style={{
                                    textAlign: "center",
                                    fontFamily: "Helvetica-Bold",
                                    fontSize: 10,
                                    marginBottom: 14,
                                }}
                            >
                                <Text>INVOICE</Text>
                            </View>
                            <View
                                style={{
                                    border: "1px solid #000",
                                    padding: 3,
                                    width: "100%",
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: "center",
                                        fontFamily: "HelveticaBold",
                                        fontSize: 7,
                                    }}
                                >
                                    {props.tunai && props.total <= props.tunai
                                        ? "Lunas"
                                        : `Jatuh Tempo pada ${props.jatuhTempo}`}
                                </Text>
                            </View>
                        </View>
                    ) : (
                        <View style={styles.containerJatuhTempo}>
                            <View
                                style={{
                                    textAlign: "center",
                                    fontFamily: "Helvetica-Bold",
                                    fontSize: 10,
                                }}
                            >
                                <Text>INVOICE</Text>
                            </View>
                        </View>
                    )} */}
                    <View style={styles.tanggalTransaksi}>
                        <Text>No. Invoice</Text>
                        <Text>{props.noInvoice}</Text>
                    </View>
                    {/* <View style={styles.tanggalTransaksi}>
                        <Text>Kasir</Text>
                        <Text>{props.kasir}</Text>
                    </View> */}
                    {props.sales !== null ? (
                        <View style={styles.tanggalTransaksi}>
                            <Text>Sales</Text>
                            <Text>{props.sales}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.tanggalTransaksi}>
                        <Text>Tanggal</Text>
                        <Text>{props.tanggalTransaksi}</Text>
                    </View>
                    {/* {props.namaMetode ? (
                        <View style={styles.tanggalTransaksi}>
                            <Text>Metode</Text>
                            <Text>{props.namaMetode}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )} */}
                    {/* {props.noMetode ? (
                        <View style={styles.tanggalTransaksi}>
                            <Text>No. Tujuan</Text>
                            <Text>{props.noMetode}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    {props.namaPelanggan ? (
                        <View style={styles.tanggalTransaksi}>
                            <Text>Pelanggan</Text>
                            <Text>{props.namaPelanggan}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )} */}
                    <View style={styles.divider}></View>
                    <View>{rows}</View>
                    {props.diskon ? (
                        <View style={styles.containerInfoTransaksi}>
                            <Text style={styles.infoTransaksi}>Diskon</Text>
                            <Text style={styles.nilaiTransaksi}>
                                {toRupiah(props.diskon)}
                            </Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    {props.pajak ? (
                        <View style={styles.containerInfoTransaksi}>
                            <Text style={styles.infoTransaksi}>Pajak</Text>
                            <Text style={styles.nilaiTransaksi}>
                                {toRupiah(props.pajak)}
                            </Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.divider}></View>
                    <View style={{ width: "100%", flexDirection: "column" }}>
                        <Text style={styles.namaBarang}>
                            Total Qty : {totalQty} pcs
                        </Text>
                    </View>
                    <View style={styles.divider}></View>
                    <View style={styles.containerInfoTransaksi}>
                        <Text style={styles.infoTransaksiBold}>Total</Text>
                        <Text style={styles.nilaiTransaksiBold}>
                            {toRupiah(props.total)}
                        </Text>
                    </View>
                    <View style={styles.divider}></View>
                    {props.tunai ? (
                        <View style={styles.containerInfoTransaksi}>
                            <Text style={styles.infoTransaksi}>
                                {props.namaMetode === "Piutang"
                                    ? props.dp === props.tunai
                                        ? "Dp"
                                        : "Sudah Dibayar"
                                    : props.namaMetode}
                            </Text>
                            <Text style={styles.nilaiTransaksi}>
                                {toRupiah(props.tunai)}
                            </Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    {props.kembalian ? (
                        <View style={styles.containerInfoTransaksi}>
                            <Text style={styles.infoTransaksi}>
                                {props.kembalian < 0 ? "Piutang" : "Kembalian"}
                            </Text>
                            <Text style={styles.nilaiTransaksi}>
                                {toRupiah(props.kembalian)}
                            </Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.divider}></View>
                    {props.belumLunas ? (
                        <>
                            <View style={styles.divider}></View>
                            <View style={styles.containerBelumLunas}>
                                <Text style={styles.penutup}>BELUM LUNAS</Text>
                            </View>
                            <View style={styles.divider}></View>
                        </>
                    ) : (
                        <View></View>
                    )}
                    {props.keterangan ? (
                        <View style={styles.keterangan}>
                            <Text>Keterangan{"  "}:</Text>
                            <Text>{props.keterangan}</Text>
                            <Text>{props.keterangan2}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.containerPenutup}>
                        {props.html ? (
                            <Html
                                stylesheet={stylesheet}
                                style={{ fontSize: 10 }}
                            >
                                {`<div>${props.html}</div>`}
                            </Html>
                        ) : (
                            // <div
                            //     dangerouslySetInnerHTML={{ __html: props.html }}
                            // />
                            <>
                                <Text style={styles.penutup}>
                                    Terimakasih telah berbelanja
                                </Text>
                                <Text style={styles.penutup}>
                                    Sampai bertemu kembali
                                </Text>
                            </>
                        )}
                    </View>
                    {/* <View style={styles.containerWaterMark}>
                        <Text>
                            {props.isPro !== 1 && (
                                <Text style={styles.boldText}>Free</Text>
                            )}{" "}
                            POS System by UKM Lokal
                        </Text>
                    </View> */}
                </View>
            </Page>
        </Document>
    );
};

export default PrintStrukPdf;

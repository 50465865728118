import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
// import { ThemeProvider } from "@mui/styles";
import React from "react";
import InfoToko from "../../components/InfoToko/InfoToko";
import CardInfoUser from "../../components/InfoAkun/CardInfoUser";
import CardChangePassword from "../../components/InfoAkun/CardChangePassword";
import LogoutIcon from "@mui/icons-material/Logout";
import Spinner from "../../components/Spinner/Spinner";
import useUser from "../../services/queries/useUser";
import { useLogoutModal } from "../../context/logoutModalContext";
import FAQ from "../../components/FAQ/FAQ";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { ParamsGetUkm } from "../../constants/types";
import useUkm from "../../services/queries/useUkm";
import { JURAGAN_URL } from "../../constants/common";

interface IPengaturanProps {}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ paddingY: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Pengaturan = (props: IPengaturanProps) => {
    // const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const { openModalLogout } = useLogoutModal();
    const { status: statusUser, data: user } = useUser();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const [paramsUkm, setParamsUkm] =
        React.useState<ParamsGetUkm>(initialParamsUkm);
    const { data: ukm } = useUkm(paramsUkm);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukmId, ukm]);

    React.useEffect(() => {
        if (user && user?.ukmId?.length > 0) {
            setParamsUkm((prev) => ({
                ...prev,
                userId: user.ukmId[0],
            }));
        }
    }, [user]);

    if (statusUser === "loading") {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height="100vh"
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <React.Fragment>
            {/* <ThemeProvider theme={theme}> */}
            <Typography fontWeight="bold" variant="h5" sx={{ marginY: 2 }}>
                Pengaturan
            </Typography>
            <Box>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                    >
                        <Tab label="Informasi Akun" {...a11yProps(0)} />

                        <Tab
                            label="Informasi UKM"
                            icon={
                                ukmData?.alamatToko === "-" ||
                                !!!ukmData?.jenisUsaha ||
                                !!!ukmData?.alamatToko ? (
                                    <Box
                                        sx={{
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                right: "16px",
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                ) : undefined
                            }
                            iconPosition="end"
                            {...a11yProps(1)}
                            sx={{
                                display:
                                    user?.role.id !== "10" ? "none" : undefined,
                            }}
                        />
                        <Tab label="Pusat Bantuan" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Grid container spacing={3}>
                        <CardInfoUser />
                        <CardChangePassword />
                        <Stack direction="row" mt={3} ml={3} spacing={2}>
                            {user?.role.id === "10" && ukmData?.isPro === 1 && (
                                <Button
                                    variant="outlined"
                                    href={
                                        process.env.NODE_ENV === "production"
                                            ? JURAGAN_URL
                                            : `http://localhost:3000`
                                    }
                                    rel="noreferrer"
                                >
                                    Ke Juragan
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="error"
                                onClick={openModalLogout}
                                startIcon={<LogoutIcon />}
                            >
                                Keluar
                            </Button>
                        </Stack>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <InfoToko />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FAQ />
                </TabPanel>
            </Box>
            {/* </ThemeProvider> */}
        </React.Fragment>
    );
};

export default Pengaturan;

import { useQuery } from "react-query";
import {
    ParamsGetPelanggan,
    GetPelangganResponse,
} from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetPelanggan) => {
    const apiGetPelanggan = queryString.stringifyUrl({
        url: "api/pelanggan",
        query: {
            ukmId: params.ukmId,
            size: params.size,
            page: params.page,
            search: `${params.search}`,
        },
    });
    const { data } = await axios.get<GetPelangganResponse>(apiGetPelanggan);
    return data.data;
};

export default function usePelanggan(params: ParamsGetPelanggan) {
    return useQuery<GetPelangganResponse["data"]>(
        ["pelanggan", params],
        () => handleRequest(params),
        { enabled: !!params.ukmId },
    );
}

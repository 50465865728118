import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDetailBarangModal } from "../../context/detailBarangModalContext";
import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    Avatar,
    Box,
} from "@mui/material";
import avatarAlt from "../../utils/avatarAlt";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const ModalDetailBarang = () => {
    const {
        isOpenModalDetailBarang,
        closeModalDetailBarang,
        namaBarang,
        deskripsi,
        url,
    } = useDetailBarangModal();

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalDetailBarang}
            onClose={closeModalDetailBarang}
            PaperProps={{
                sx: {
                    //   minHeight: "150px",
                    //   maxHeight: "150px",
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalDetailBarang}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Barang
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" justifyContent="center">
                    <Avatar
                        sx={{
                            bgcolor: "primary.main",
                            width: "30vh",
                            height: "30vh",
                            display: "flex",
                            fontSize: "3em",
                        }}
                        variant="rounded"
                        alt={namaBarang}
                        src={url}
                    >
                        {avatarAlt(namaBarang)}
                    </Avatar>
                </Box>
                <Box display="flex" justifyContent="center" marginY={2}>
                    <Typography variant="h6" fontWeight="bold">
                        {namaBarang}
                    </Typography>
                </Box>
                {deskripsi ? (
                    <React.Fragment>
                        <Typography variant="subtitle1">Deskripsi:</Typography>
                        <Typography variant="subtitle1">{deskripsi}</Typography>
                    </React.Fragment>
                ) : undefined}
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailBarang;

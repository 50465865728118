import { Cookies } from "react-cookie";
import defaultAxios, { AxiosError } from "axios";
import React, {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { CART_DISPLAY, DISPLAY_INFO, tokenKey } from "../constants/common";
import {
    AuthState,
    CheckToken,
    LogoutResponse,
    LoginBody,
    LoginResponse,
    UserResponse,
    RegistrasiForm,
    RegistrasiResponse,
} from "../constants/types";
// import * as SplashScreen from "expo-splash-screen";
// import * as Font from "expo-font";
import axios from "../services/axios";
// import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useQueryClient } from "react-query";
// import { Alert } from "react-native";
// import Notifications from "expo-notifications";
// import registerForPushNotif from "../utils/common/registerForPushNotif";
import Swal from "sweetalert2";
import { getLocalUkmId, setLocalUkmId } from "../utils/handleChangeUkmId";
// import { getDomainWithoutSubdomain } from "../utils/getDomainWithoutSubdomain";
import { saveToStorage } from "../utils/saveFromStorage";

interface IAuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

const AuthProvider = ({ children }: IAuthProviderProps) => {
    const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
    const [isRegistered, setRegistered] = useState<boolean>(false);
    const [isPreparingApp, setPreparingApp] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [ukmIdUser, setUkmIdUser] = useState<number[]>();
    const initialCount = Number(localStorage.getItem("loginCount")) ?? 0;
    const [loginCount, setLoginCount] = useState<number>(initialCount);
    const cookies = useMemo(() => new Cookies(), []);
    const queryClient = useQueryClient();

    // const deviceId = (await Notifications.getDevicePushTokenAsync()).data;

    // const handleSetDeviceId = useCallback(async () => {
    //     const deviceId = (await Notifications.getDevicePushTokenAsync()).data;
    //     try {
    //         await AsyncStorage.setItem(deviceIdKey, deviceId);
    //     } catch (error) {
    //         throw error;
    //     }
    // }, [AsyncStorage.setItem]);
    // console.log(deviceId);

    const handleRemoveToken = useCallback(async () => {
        try {
            await cookies.remove(tokenKey);
        } catch (error) {
            throw error;
        }
    }, [cookies]);

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    console.log(`serverError`, serverError.response);
                    if (loginCount >= 5) {
                        Swal.fire({
                            title: "Batas upaya masuk tercapai!",
                            text: `Anda harus menunggu 5 menit sebelum bisa mencoba masuk kembali`,
                            icon: "error",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                    } else if (serverError.response!.status === 400) {
                        Swal.fire({
                            title: "Terjadi Kesalahan!",
                            text: `${serverError.response.data.data.errors.message}`,
                            icon: "error",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        setLoginCount(
                            serverError.response.data.data.jumlah_gagal,
                        );
                        localStorage.setItem(
                            "loginCount",
                            String(serverError.response.data.data.jumlah_gagal),
                        );
                        console.log(
                            "",
                            `${serverError.response.data.data.jumlah_gagal}`,
                            [{ text: "OK" }],
                        );
                    }

                    if (serverError.response!.status === 422) {
                        Swal.fire({
                            title: "Terjadi Kesalahan!",
                            text: `Ada error validasi`,
                            icon: "error",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        console.log(
                            "",
                            `${serverError.response.data.message}`,
                            [{ text: "OK" }],
                        );
                    }

                    if (serverError.response!.status === 403) {
                        // Alert.alert("", `Maaf, akun Anda tidak terautentikasi.`,
                        // console.log("", `Maaf, akun Anda tidak terautentikasi.`, [
                        //     { text: "OK" },
                        // ]);
                        Swal.fire({
                            title: "Terjadi Kesalahan!",
                            text: `${serverError.response.data.message}`,
                            icon: "error",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        console.log("", `${serverError.response.data.data}`, [
                            { text: "OK" },
                        ]);
                    }
                } else {
                    // Alert.alert("", `Terjadi kesalahan! Silahkan coba lagi.`,
                    console.error(
                        "",
                        `Terjadi kesalahan! Silahkan coba lagi.`,
                        [{ text: "OK" }],
                    );
                }
            }
        },
        [loginCount],
    );

    useEffect(() => {
        console.log("Login count: ", loginCount);
    }, [loginCount]);

    const checkToken = useCallback(
        async (token: string) => {
            try {
                const { data } = await axios.get<CheckToken>(
                    `/api/cektoken?token=${token}`,
                );
                console.log("checked");
                if (token && data.code === 200) {
                    const { data: user } = await axios.get<UserResponse>(
                        "/api/users",
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    const ukmId = getLocalUkmId(user?.data?.userId);
                    // console.log("Ukm ID: ", user.data.ukmId);
                    if (ukmId) {
                        setUkmIdUser([ukmId]);
                    } else {
                        setUkmIdUser(user.data.ukmId);
                        setLocalUkmId(user.data.userId, user.data.ukmId[0]);
                    }
                    setAuthenticated(true);
                }
            } catch (error) {
                handleErrorResponse(error);
                setAuthenticated(false);
                handleRemoveToken();
                queryClient.clear();
            }
        },
        [handleErrorResponse, handleRemoveToken, queryClient],
    );

    const loadResourcesAndDataAsync = useCallback(async () => {
        try {
            const token = await cookies.get(tokenKey);
            if (token) {
                await checkToken(token);
            }
        } catch (e) {
            console.warn(e);
        } finally {
            setPreparingApp(false);
        }
    }, [checkToken, cookies]);

    useEffect(() => {
        loadResourcesAndDataAsync();
    }, [loadResourcesAndDataAsync]);

    const handleSetToken = useCallback(
        async (token: any) => {
            try {
                await cookies.set(tokenKey, token);
            } catch (error) {
                throw error;
            }
        },
        [cookies],
    );

    const handleRegistered = () => {
        setRegistered(true);
    };

    const handleAuthenticated = (value: boolean) => {
        setAuthenticated(value);
    };

    const login = useCallback(
        async (values: LoginBody) => {
            setLoading(true);
            try {
                const { data } = await axios.post<LoginResponse>("/api/login", {
                    ...values,
                });
                console.log(`data`, data);
                const { data: user } = await axios.get<UserResponse>(
                    "/api/users",
                    {
                        headers: {
                            Authorization: `Bearer ${data.data.token}`,
                        },
                    },
                );
                // console.log("Ukm ID: ", user.data.ukmId);
                const ukmId = getLocalUkmId(user?.data?.userId);
                if (ukmId) {
                    setUkmIdUser([ukmId]);
                } else {
                    setUkmIdUser(user.data.ukmId);
                    setLocalUkmId(user.data.userId, user.data.ukmId[0]);
                }
                if (data.code === 200) {
                    setAuthenticated(true);
                    handleSetToken(data.data.token);
                    saveToStorage(CART_DISPLAY, JSON.stringify([]));
                    saveToStorage(
                        DISPLAY_INFO,
                        JSON.stringify({
                            diskon: 0,
                            kembalian: 0,
                            pajak: 0,
                            persentaseDiskon: 0,
                            persentasePajak: 0,
                            totalTagihan: 0,
                            uangDibayar: 0,
                        }),
                    );
                    // setLoginCount(0);
                    localStorage.removeItem("loginCount");
                    // checkToken(data.data.token);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                handleErrorResponse(error);
            }
        },
        [handleErrorResponse, handleSetToken],
    );

    const resetLoginCount = () => {
        setLoginCount(0);
        localStorage.removeItem("loginCount");
    };

    const register = useCallback(
        async (values: RegistrasiForm) => {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append("nama", values.nama);
                formData.append("username", values.username);
                formData.append("email", values.email);
                formData.append("no_telp", values.no_telp);
                formData.append("password", values.password);
                formData.append("role", "10");
                // formData.append("alamat", "");
                // formData.append("gambar", "");
                const { data } = await axios.post<RegistrasiResponse>(
                    "/api/register",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                console.log(`data register`, data);

                if (data.code === 200) {
                    setAuthenticated(true);
                    saveToStorage(CART_DISPLAY, JSON.stringify([]));
                    handleSetToken(data.data.token);
                    // checkToken(data.data.token);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                handleErrorResponse(error);
            }
        },
        [handleErrorResponse, handleSetToken],
    );

    const logout = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await axios.get<LogoutResponse>("/api/logout");
            if (data.code === 200) {
                setAuthenticated(false);
                saveToStorage(CART_DISPLAY, JSON.stringify([]));
                handleRemoveToken();
                queryClient.clear();
            }
            setLoading(false);
        } catch (error) {
            handleErrorResponse(error);
            setLoading(false);
        }
    }, [handleErrorResponse, handleRemoveToken, queryClient]);

    const value = useMemo(
        () => ({
            isAuthenticated,
            isLoading,
            isRegistered,
            checkToken,
            login,
            logout,
            ukmIdUser,
            register,
            handleRegistered,
            setRegistered,
            handleAuthenticated,
            handleSetToken,
            loginCount,
            resetLoginCount,
        }),
        [
            isAuthenticated,
            isLoading,
            isRegistered,
            checkToken,
            login,
            logout,
            ukmIdUser,
            register,
            handleSetToken,
            loginCount,
        ],
    );

    if (isPreparingApp) return null;

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
};

export { AuthContext, AuthProvider, useAuth };

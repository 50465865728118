import React, {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { DetailBundling, ICart } from "../constants/types";
import Swal from "sweetalert2";
import { saveToStorage } from "../utils/saveFromStorage";
import { CART_DISPLAY } from "../constants/common";

interface ICustomerDisplay {
    diskon: number;
    kembalian: number;
    pajak: number;
    persentaseDiskon?: number | null;
    persentasePajak?: number | null;
    totalTagihan: number;
    uangDibayar: number;
}

interface State {
    handleUpdate: (data: ICart) => void;
    handleUpdateByBarcode: (data: ICart) => void;
    // handleUpdateBundling: (data: ICart, type: "plus" | "minus") => void;
    handleRemove: (data: ICart) => void;
    hitungHarga: () => void;
    totalHarga: number;
    hitungBayar: (digit: number) => void;
    bayar?: number | null;
    hitungKembalian: () => void;
    kembalian: number;
    uangPas: () => void;
    handleReset: () => void;
    openModalTransaksi: () => void;
    closeModalTransaksi: () => void;
    isOpenModalTransaksi: boolean;
    openModalBayar: (keteranganBeli?: string | null) => void;
    closeModalBayar: () => void;
    isOpenModalBayar: boolean;
    openModalKonfirmasiBayar: () => void;
    closeModalKonfirmasiBayar: () => void;
    isOpenModalKonfirmasiBayar: boolean;
    hitungGrandTotal: () => void;
    aturDiskon: (digit: number, toggleDiskon: "nominal" | "persentase") => void;
    diskon: number;
    grandTotal: number;
    addToCart: (data: ICart) => void;
    cart: ICart[];
    resetCart: () => void;
    maxItem: boolean;
    handlerErrorMaxItem: () => void;
    resetErrorMaxItem: () => void;
    handlingAfterPrint: () => void;
    resetPrint: () => void;
    isPrintStruk: boolean;
    isFetchingItems: boolean;
    setFetchingItems: () => void;
    cancelFetchingItems: () => void;
    isDonePembayaran: boolean;
    handlingDonePembayaran: () => void;
    handlingResetPembayaran: () => void;
    keteranganBeli: string | null;
    handleFaktur: (id: number, faktur: ICart["faktur"]) => void;
    checked: boolean;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    isKeterangan: boolean;
    handleIsKeterangan: (checked: boolean) => void;
    handleFakturBundling: (
        idBundling: number,
        newFaktur: ICart["faktur"],
        idSatuan: number,
    ) => void;
    resetKeteranganBeli: () => void;
    handleChangeKeteranganBeli: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
    diskonDisplay: number;
    kembalianDisplay: number;
    pajak: number;
    persentaseDiskon?: number | null;
    persentasePajak?: number | null;
    totalTagihan: number;
    uangDibayar: number;
    handleCustomerDisplay: (values: ICustomerDisplay) => void;
}

interface ITransaksiContext {
    children: ReactNode;
}

interface IQty {
    id: number;
    qty: number;
}

const TransaksiContext = createContext<State | undefined>(undefined);

const TransaksiProvider = ({ children }: ITransaksiContext) => {
    const [cart, setCart] = useState<ICart[]>([]);
    const [isOpenModalTransaksi, setisOpenModalTransaksi] = useState(false);
    const [isOpenModalBayar, setisOpenModalBayar] = useState(false);
    const [isOpenModalKonfirmasiBayar, setIsOpenKonfirmasiBayar] =
        useState(false);
    const [totalHarga, setTotalHarga] = useState(0);
    const [bayar, setBayar] = useState<number | null>();
    const [grandTotal, setGrandTotal] = useState(0);
    const [maxItem, setMaxItem] = useState(false);
    const [isPrintStruk, setIsPrintStruk] = useState(false);
    const [isFetchingItems, setIsFetchingItems] = useState(false);
    const [isDonePembayaran, setIsDonePembayaran] = useState(false);
    const [keteranganBeli, setKeteranganBeli] = useState<string | null>(null);
    const [checked, setChecked] = useState(false);
    const [isKeterangan, setIsKeterangan] = useState(false);
    const [diskon, setDiskon] = useState(0);
    const [kembalian, setKembalian] = useState(0);
    const [diskonDisplay, setDiskonDisplay] = useState(0);
    const [kembalianDisplay, setKembalianDisplay] = useState(0);
    const [pajak, setPajak] = useState(0);
    const [persentaseDiskon, setPersentaseDiskon] = useState<
        number | null | undefined
    >();
    const [persentasePajak, setPersentasePajak] = useState<
        number | null | undefined
    >();
    const [totalTagihan, setTotalTagihan] = useState(0);
    const [uangDibayar, setUangDibayar] = useState(0);

    useEffect(() => {
        if (cart) {
            saveToStorage(CART_DISPLAY, JSON.stringify(cart));
        }
    }, [cart]);

    const handleCustomerDisplay = (values: ICustomerDisplay) => {
        setDiskonDisplay(values.diskon);
        setKembalianDisplay(values.kembalian);
        setPajak(values.pajak);
        setPersentaseDiskon(values.persentaseDiskon);
        setPersentasePajak(values.persentasePajak);
        setTotalTagihan(values.totalTagihan);
        setUangDibayar(values.uangDibayar);
    };

    const handleIsKeterangan = (checked: boolean) => {
        if (checked) {
            setIsKeterangan(true);
        } else {
            setIsKeterangan(false);
        }
    };

    const handlerErrorMaxItem = useCallback(() => {
        setMaxItem(true);
    }, []);

    const addToCart = useCallback(
        (data: ICart) => {
            const selectedCart = cart.filter((li) => li.id === data.id);
            if (selectedCart.length > 0) {
                const newCart = [...cart];
                const index = cart.findIndex((c) => {
                    return c.id === data.id;
                });
                newCart[index] = { ...data, qty: cart[index].qty + 1 };

                const isBundling = Number(data?.detailBundling.length) > 0;
                if (isBundling) {
                    // Cari index product terkair bundling
                    const bundling = data?.detailBundling;
                    const indexes: number[] = []; // [1, 2, 3]
                    bundling?.forEach((bund) => {
                        const index = cart.findIndex((li) => li.id === bund.id);
                        if (index > -1) {
                            indexes.push(index);
                        }
                    });

                    // Kurangi jumlah stok product satuan
                    indexes.forEach((idx) => {
                        const jumlahDiambil = data?.detailBundling.find(
                            (li) => li.id === newCart[idx].id,
                        )?.jumlahYangDiambil;
                        newCart[idx] = {
                            ...newCart[idx],
                            jumlahStok:
                                Number(newCart[idx].jumlahStok) -
                                Number(jumlahDiambil),
                        };
                    });
                }

                if (cart) {
                    if (
                        Number(newCart[index].qty) <=
                            Number(cart[index].jumlahStok) ||
                        Number(newCart[index].qty) !== null
                    ) {
                        setCart(newCart);
                    }
                }

                if (
                    Number(newCart[index].qty) >
                        Number(cart[index].jumlahStok) &&
                    Number(newCart[index].qty) !== null
                ) {
                    handlerErrorMaxItem();
                }
            } else {
                const newCart = [...cart];
                newCart.push(data);

                setCart((prev) => [...prev, data]);
            }
        },
        [cart, handlerErrorMaxItem],
    );

    const handleFaktur = useCallback(
        (id: number, faktur: ICart["faktur"]) => {
            const newCart = cart;
            const indexItem = cart.findIndex((li) => li.id === id);
            newCart[indexItem].faktur = faktur;
            setCart(newCart);
        },
        [cart],
    );

    const handleFakturBundling = useCallback(
        (idBundling: number, newFaktur: ICart["faktur"], idSatuan: number) => {
            const newCart = cart;
            const indexBundling = cart.findIndex((li) => li.id === idBundling);
            const indexSatuan = cart[indexBundling].detailBundling.findIndex(
                (li) => li.id === idSatuan,
            );
            newCart[indexBundling].detailBundling[indexSatuan].newFaktur =
                newFaktur;
            setCart(newCart);
        },
        [cart],
    );

    const resetCart = useCallback(() => {
        setCart([]);
    }, []);

    const resetKeteranganBeli = useCallback(() => {
        setKeteranganBeli("");
    }, []);

    const resetErrorMaxItem = useCallback(() => {
        setMaxItem(false);
    }, []);

    const handlingAfterPrint = useCallback(() => {
        setIsPrintStruk(true);
    }, []);

    const resetPrint = useCallback(() => {
        setIsPrintStruk(false);
    }, []);

    const openModalTransaksi = () => {
        setisOpenModalTransaksi(true);
    };

    const closeModalTransaksi = () => {
        setisOpenModalTransaksi(false);
    };

    const handleChangeKeteranganBeli = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setKeteranganBeli(event.target.value);
    };

    const openModalBayar = useCallback(() => {
        const newCart = [...cart];

        // console.log(newCart);
        const barangPaket = cart.filter((li) => li.detailBundling.length > 0);
        // console.log(barangPaket);

        const barangSatuan = cart.filter(
            (li) => li.detailBundling.length === 0,
        );
        // console.log(barangSatuan);

        const detailBundling = barangPaket.map((li) => li.detailBundling);
        // console.log(detailBundling);

        const totalSatuan = barangSatuan.map((li) => ({
            id: li.id,
            qty: li.qty,
        }));
        console.log("Total Satuan", totalSatuan);

        const mergedDetailBundling = ([] as DetailBundling[]).concat.apply(
            [],
            detailBundling,
        );
        // console.log(mergedDetailBundling);

        const bundlingIds: ICart[] = [];
        mergedDetailBundling.forEach((li) => {
            const bundlingId = li.bundlingId;
            const findId = cart.find((li) => li.id === bundlingId);
            if (findId) {
                bundlingIds.push(findId);
            }
        });
        // console.log(bundlingIds);

        const perkalianQty: IQty[] = [];
        bundlingIds.forEach((li, idx, arr) => {
            const total =
                arr[idx].qty * mergedDetailBundling[idx].jumlahYangDiambil;
            // const ayee = bundlingIds[idx].jumlahYangDiambil;
            const object = {
                id: mergedDetailBundling[idx].id,
                qty: total,
            };
            // console.log(total);
            // console.log(object);
            // if (findId) {
            perkalianQty.push(object);
            // }
        });
        // console.log(perkalianQty);

        const totalAllPerkalianQty: IQty[] = [];
        perkalianQty.forEach((x) => {
            const obj = totalAllPerkalianQty.find((o) => o.id === x.id);
            if (obj) {
                obj.qty = obj.qty + x.qty;
            } else {
                totalAllPerkalianQty.push(x);
            }
        });
        console.log("Total Perkalian dalam Bundle", totalAllPerkalianQty);

        const grandTotalAll: IQty[] = [];
        totalSatuan.concat(totalAllPerkalianQty).forEach((x) => {
            const obj = grandTotalAll.find((o) => o.id === x.id);
            if (obj) {
                obj.qty = obj.qty + x.qty;
            } else {
                grandTotalAll.push(x);
            }
        });
        console.log("Total qty all item satuan", grandTotalAll);

        const indexes: number[] = []; // [1, 2, 3]
        grandTotalAll.forEach((li, idx, arr) => {
            const barang = cart.find((li) => li.id === arr[idx].id);
            console.log(barang);
            if (barang) {
                const indexSatuan = cart.findIndex((li) => li.id === barang.id);
                console.log(indexSatuan);
                if (indexSatuan || indexSatuan === 0) {
                    // console.log("index barang satuan", indexSatuan);
                    // console.log(barang);
                    indexes.push(indexSatuan);
                }
            }
        });

        const res: number[] = [];
        indexes.forEach((idx) => {
            const jumlahDiambil = grandTotalAll.find(
                (li) => li.id === newCart[idx].id,
            )?.qty;
            // console.log("Jumlah diambil ", jumlahDiambil);
            if (newCart[idx].jumlahStok !== null) {
                const hasilAkhirSatuan =
                    Number(newCart[idx].jumlahStok) - Number(jumlahDiambil);
                // console.log("Hasil Akhir ", hasilAkhirSatuan);
                res.push(hasilAkhirSatuan);
            }
            // newCart[idx] = {
            //   ...newCart[idx],
            //   jumlahStok: Number(newCart[idx].jumlahStok) - Number(jumlahDiambil)
            // };
        });

        const filteredRes = res.filter((li) => li < 0).length;
        const filteredQtyIs0 = cart.filter((li) => li.qty <= 0);

        if (filteredRes > 0) {
            Swal.fire({
                title: "Jumlah pesanan barang melebihi stok",
                position: "top-end",
                showConfirmButton: false,
                icon: "error",
                toast: true,
                timer: 3000,
                timerProgressBar: true,
                showCloseButton: true,
                customClass: {
                    container: "my-swal",
                },
            });
        } else if (filteredQtyIs0.length > 0) {
            Swal.fire({
                title: "Pesanan tidak boleh kurang 1",
                position: "top-end",
                showConfirmButton: false,
                icon: "error",
                toast: true,
                timer: 5000,
                customClass: {
                    container: "my-swal",
                },
            });
        } else {
            setisOpenModalBayar(true);
            setBayar(null);
        }
    }, [cart]);

    const closeModalBayar = () => {
        setisOpenModalBayar(false);
    };

    const openModalKonfirmasiBayar = useCallback(() => {
        setIsOpenKonfirmasiBayar(true);
        setisOpenModalBayar(false);
    }, []);

    const closeModalKonfirmasiBayar = useCallback(() => {
        setIsOpenKonfirmasiBayar(false);
        setisOpenModalBayar(true);
    }, []);

    const setFetchingItems = useCallback(() => {
        setIsFetchingItems(true);
    }, []);

    const cancelFetchingItems = useCallback(() => {
        setIsFetchingItems(false);
    }, []);

    const handlingDonePembayaran = useCallback(() => {
        setIsDonePembayaran(true);
    }, []);

    const handlingResetPembayaran = useCallback(() => {
        setIsDonePembayaran(false);
    }, []);

    const hitungHarga = useCallback(() => {
        let total = 0;
        cart.forEach((c) => {
            total += c.harga * c.qty;
        });
        setTotalHarga(total);
    }, [cart]);

    const hitungBayar = useCallback((digit: number) => {
        setBayar(digit);
    }, []);

    const hitungKembalian = useCallback(() => {
        // console.log(kembalian);
        if (bayar === 0 || bayar === null) {
            setKembalian(0);
        } else {
            if (diskon === 0) {
                const change = (bayar ?? 0) - totalHarga;
                setKembalian(change);
            } else {
                const change = (bayar ?? 0) - grandTotal;
                setKembalian(change);
            }
        }
        // if (totalHarga > 0) {
        // }
    }, [bayar, diskon, grandTotal, totalHarga]);

    const aturDiskon = useCallback(
        (digit: number, toggleDiskon: "nominal" | "persentase") => {
            if (toggleDiskon === "nominal") {
                setDiskon(digit);
            } else if (toggleDiskon === "persentase") {
                const potongan = digit / 100;
                const totalDiskon = totalHarga - totalHarga * potongan;
                setDiskon(totalHarga - totalDiskon);
            }
            // console.log(digit);
            // console.log(toggleDiskon);
        },
        [totalHarga],
    );

    const hitungGrandTotal = useCallback(() => {
        // if (diskon === 0) {
        //   setGrandTotal(0);
        // } else {
        // const potongan = diskon / 100;
        // const grand = totalHarga - totalHarga * potongan;
        setGrandTotal(totalHarga - diskon);
        // }
    }, [diskon, totalHarga]);

    const handleReset = useCallback(() => {
        setCart([]);
        setTotalHarga(0);
        setBayar(null);
        setKembalian(0);
        setDiskon(0);
        setIsKeterangan(false);
        setKeteranganBeli("");
    }, []);

    const uangPas = useCallback(() => {
        if (diskon === 0) {
            setBayar(totalHarga);
        } else {
            setBayar(grandTotal);
        }
    }, [diskon, grandTotal, totalHarga]);

    const handleUpdate = useCallback(
        (data: ICart) => {
            const newCart = [...cart];
            const isIncludes = cart.findIndex((c) => c.id === data.id);

            // for satuan
            if (isIncludes < 0) {
                newCart.push(data);

                setCart(newCart);
            } else {
                const index = cart.findIndex((c) => {
                    return c.id === data.id;
                });
                newCart[index] = { ...data, qty: data.qty };

                setCart(newCart);
            }
        },
        [cart],
    );

    // const handleUpdateBundling = useCallback(
    //     (data: ICart, type: "plus" | "minus") => {
    //         const newCart = [...cart];

    //         const idxBarang = cart.findIndex((li) => li.id === data.id);
    //         const barang = cart.find((li) => li.id === data.id);
    //         const isBundling = Number(barang?.detailBundling.length) > 0;

    //         if (isBundling) {
    //             // Cari index product terkair bundling
    //             const bundling = barang?.detailBundling;
    //             const indexes: number[] = []; // [1, 2, 3]
    //             bundling?.forEach((bund) => {
    //                 const index = cart.findIndex((li) => li.id === bund.id);
    //                 if (index > -1) {
    //                     indexes.push(index);
    //                 }
    //             });

    //             newCart[idxBarang] = {
    //                 ...data,
    //             };

    //             // Kurangi jumlah stok product satuan
    //             indexes.forEach((idx) => {
    //                 const jumlahDiambil = barang?.detailBundling.find(
    //                     (li) => li.id === newCart[idx].id,
    //                 )?.jumlahYangDiambil;
    //                 console.log("Jumlah diambil ", jumlahDiambil);
    //                 newCart[idx] = {
    //                     ...newCart[idx],
    //                     jumlahStok:
    //                         type === "plus"
    //                             ? Number(newCart[idx].jumlahStok) -
    //                               Number(jumlahDiambil)
    //                             : Number(newCart[idx].jumlahStok) +
    //                               Number(jumlahDiambil),
    //                 };
    //             });

    //             setCart(newCart);
    //         }
    //     },
    //     [cart],
    // );

    const handleUpdateByBarcode = useCallback(
        (data: ICart) => {
            const newCart = [...cart];
            const isIncludes = cart.findIndex((c) => c.sku === data.sku);

            if (isIncludes < 0) {
                newCart.push(data);

                setCart(newCart);
            } else {
                const index = cart.findIndex((c) => {
                    return c.sku === data.sku;
                });
                newCart[index] = data;

                setCart(newCart);
            }
        },
        [cart],
    );

    const handleRemove = useCallback(
        (data: ICart) => {
            const newCart = cart.filter((c) => c.id !== data.id);

            setCart(newCart);
        },
        [cart],
    );

    useEffect(() => {
        hitungHarga();
        hitungKembalian();
        hitungGrandTotal();
    }, [
        cart,
        bayar,
        totalHarga,
        diskon,
        hitungHarga,
        hitungKembalian,
        hitungGrandTotal,
    ]);

    useEffect(() => {
        console.log(cart);
    }, [cart]);

    const value = useMemo(
        () => ({
            handleUpdate,
            handleUpdateByBarcode,
            // handleUpdateBundling,
            handleRemove,
            totalHarga,
            hitungHarga,
            hitungBayar,
            bayar,
            hitungKembalian,
            kembalian,
            uangPas,
            handleReset,
            isOpenModalTransaksi,
            openModalTransaksi,
            closeModalTransaksi,
            isOpenModalBayar,
            openModalBayar,
            closeModalBayar,
            isOpenModalKonfirmasiBayar,
            openModalKonfirmasiBayar,
            closeModalKonfirmasiBayar,
            aturDiskon,
            diskon,
            grandTotal,
            hitungGrandTotal,
            addToCart,
            cart,
            resetCart,
            maxItem,
            handlerErrorMaxItem,
            resetErrorMaxItem,
            handlingAfterPrint,
            resetPrint,
            isPrintStruk,
            isFetchingItems,
            setFetchingItems,
            cancelFetchingItems,
            isDonePembayaran,
            handlingDonePembayaran,
            handlingResetPembayaran,
            keteranganBeli,
            handleFaktur,
            checked,
            setChecked,
            isKeterangan,
            handleIsKeterangan,
            handleFakturBundling,
            resetKeteranganBeli,
            handleChangeKeteranganBeli,
            diskonDisplay,
            kembalianDisplay,
            pajak,
            persentaseDiskon,
            persentasePajak,
            totalTagihan,
            uangDibayar,
            handleCustomerDisplay,
        }),
        [
            handleUpdate,
            handleUpdateByBarcode,
            handleRemove,
            totalHarga,
            hitungHarga,
            hitungBayar,
            bayar,
            hitungKembalian,
            kembalian,
            uangPas,
            handleReset,
            isOpenModalTransaksi,
            isOpenModalBayar,
            openModalBayar,
            isOpenModalKonfirmasiBayar,
            openModalKonfirmasiBayar,
            closeModalKonfirmasiBayar,
            aturDiskon,
            diskon,
            grandTotal,
            hitungGrandTotal,
            addToCart,
            cart,
            resetCart,
            maxItem,
            handlerErrorMaxItem,
            resetErrorMaxItem,
            handlingAfterPrint,
            resetPrint,
            isPrintStruk,
            isFetchingItems,
            setFetchingItems,
            cancelFetchingItems,
            isDonePembayaran,
            handlingDonePembayaran,
            handlingResetPembayaran,
            keteranganBeli,
            handleFaktur,
            checked,
            isKeterangan,
            handleFakturBundling,
            resetKeteranganBeli,
            diskonDisplay,
            kembalianDisplay,
            pajak,
            persentaseDiskon,
            persentasePajak,
            totalTagihan,
            uangDibayar,
        ],
    );

    return (
        <TransaksiContext.Provider value={value}>
            {children}
        </TransaksiContext.Provider>
    );
};

const useTransaksi = () => {
    const context = React.useContext(TransaksiContext);
    if (context === undefined) {
        throw new Error("useTransaksi must be used within a TransaksiProvider");
    }
    return context;
};

export { TransaksiContext, TransaksiProvider, useTransaksi };

import {
    Button,
    Card,
    CardContent,
    Box,
    Avatar,
    Stack,
    Typography,
    Badge,
    ListItemButton,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import avatarAlt from "../../utils/avatarAlt";
import { useUkmModal } from "../../context/ukmModalContext";
import useProvinsi from "../../services/queries/useProvinsi";
import useKota from "../../services/queries/useKota";
import moment from "moment";
import "moment/locale/id";
import useTime from "../../services/queries/useTime";
import { ContentUkm } from "../../constants/types";
import ModalAlertPro from "../ModalAlertPro/ModalAlertPro";

moment.locales();
moment.locale("id");

interface ICardInfoTokoProps {
    id: number;
    data: ContentUkm;
}

const CardInfoToko = ({ id, data }: ICardInfoTokoProps) => {
    const {
        gambarToko,
        namaToko,
        warna,
        alamatToko,
        noTelp,
        noTelpArr,
        deskripsi,
        besarUsaha,
        jenisUsaha,
        omset,
        isPro,
        isActive,
        provinsi,
        kota,
        pembayaranBerikutnya,
        lewatTempoPro,
        statusPembayaran,
        orderId,
        bank,
        layanan,
    } = data;
    const { openModalUkm } = useUkmModal();
    // const { openModalHapusUkm } = useHapusUkmModal();
    const { data: provinsiOptions } = useProvinsi();
    const { data: kotaOptions } = useKota();
    const { data: time } = useTime();
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);

    const isSudahJatuhTempo = moment(time?.datetime).isAfter(
        pembayaranBerikutnya,
    );

    useEffect(
        () => console.log("isSudahJatuhTempo", isSudahJatuhTempo),
        [isSudahJatuhTempo],
    );

    const selectedProvinsi = provinsiOptions?.content.find(
        (li) => li.nama === provinsi,
    );
    const selectedKota = kotaOptions?.content.find((li) => li.nama === kota);

    const buttonText = useMemo(() => {
        if (lewatTempoPro === 1) {
            return "Segera ajukan aktivasi";
        } else if (
            isActive === 1 &&
            statusPembayaran !== "ACTIVE" &&
            pembayaranBerikutnya !== null
        ) {
            return "Perbarui Langganan";
        } else if (
            statusPembayaran === "ACTIVE" &&
            pembayaranBerikutnya !== null
        ) {
            return "Kembali ke Pembayaran";
        } else if (statusPembayaran === "-" && pembayaranBerikutnya === null) {
            return "Sedang Proses";
        } else if (
            pembayaranBerikutnya === null &&
            isActive === 0 &&
            statusPembayaran !== "ACTIVE"
        ) {
            return "Ajukan Aktivasi";
        } else if (statusPembayaran === "ACTIVE" || statusPembayaran === null) {
            return "Sedang Proses";
        } else if (
            pembayaranBerikutnya !== null &&
            isPro === 1 &&
            statusPembayaran === null
        ) {
            return "Perbarui Langganan";
        } else {
            return "Perbarui Langganan";
        }
    }, [
        lewatTempoPro,
        isActive,
        statusPembayaran,
        pembayaranBerikutnya,
        isPro,
    ]);

    return (
        <>
            <Badge
                invisible={alamatToko !== "-" || !!!jenisUsaha || !!!alamatToko}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                variant="dot"
                sx={{
                    width: "100%",
                    "& .MuiBadge-badge": {
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#fa4c46",
                        color: "#fa4c46",
                        boxShadow: `0 0 0 2px #fff`,
                        "&::after": {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            animation: "ripple 1.2s infinite ease-in-out",
                            border: "1px solid currentColor",
                            content: '""',
                        },
                    },
                    "@keyframes ripple": {
                        "0%": {
                            transform: "scale(.8)",
                            opacity: 1,
                        },
                        "100%": {
                            transform: "scale(2.4)",
                            opacity: 0,
                        },
                    },
                }}
            >
                <Card
                    component={ListItemButton}
                    variant="outlined"
                    onClick={() => {
                        if (isActive === 0 || isSudahJatuhTempo) return;
                        openModalUkm({
                            id: id,
                            // gambarToko: gambarToko ?? null,
                            namaToko: namaToko,
                            warna: warna,
                            alamatToko: alamatToko,
                            noTelp: noTelp,
                            noTelpArr: noTelpArr.map((li) => ({ noTelp: li })),
                            deskripsi: deskripsi ?? "",
                            besarUsaha: besarUsaha,
                            jenisUsaha: jenisUsaha,
                            omset: String(omset),
                            provinsi: selectedProvinsi,
                            kota: selectedKota,
                        });
                    }}
                >
                    <CardContent sx={{ width: "100%" }}>
                        <Box display="flex" justifyContent="center">
                            <Avatar
                                alt={namaToko}
                                src={gambarToko === null ? "" : gambarToko}
                                sx={{
                                    bgcolor: "primary.main",
                                    width: 100,
                                    height: 100,
                                    marginY: 3,
                                }}
                            >
                                {avatarAlt(namaToko)}
                            </Avatar>
                        </Box>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {namaToko}
                            </Typography>
                            <Box
                                bgcolor={warna ?? "primary.main"}
                                borderRadius="50%"
                                sx={{
                                    maxWidth: "22px",
                                    minWidth: "22px",
                                    maxHeight: "22px",
                                    minHeight: "22px",
                                }}
                            />
                        </Stack>
                        <Typography
                            component="div"
                            variant="body2"
                            color="#808080"
                            sx={{ marginBottom: 2 }}
                        >
                            Status:{" "}
                            <Typography
                                display="inline"
                                variant="body2"
                                color={isPro === 1 ? "#45A779" : "#808080"}
                            >
                                {isPro === 1 ? "Pro " : "Free "}
                            </Typography>
                            <Typography
                                display="inline"
                                variant="body2"
                                color={
                                    isActive === 1 && !isSudahJatuhTempo
                                        ? "#45A779"
                                        : "error"
                                }
                            >
                                {isActive === 1 && !isSudahJatuhTempo
                                    ? "(Aktif)"
                                    : "(Nonaktif)"}
                            </Typography>
                        </Typography>
                        <Typography variant="body2" sx={{ marginBottom: 2 }}>
                            No. Telp: +{noTelp}
                        </Typography>
                        <Stack spacing={1}>
                            <Button
                                variant="contained"
                                disableElevation
                                fullWidth
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (statusPembayaran === "ACTIVE") {
                                        return;
                                    }
                                    setIsOpenModalAlertPro(true);
                                }}
                                href={
                                    statusPembayaran === "ACTIVE"
                                        ? `/payment/${orderId}/${layanan.id}/${id}/metode/va?code=${bank}`
                                        : undefined
                                }
                                color={
                                    lewatTempoPro === 1
                                        ? "lightwarning"
                                        : (statusPembayaran === "EXPIRED" ||
                                              statusPembayaran === "PAID" ||
                                              statusPembayaran === "-") &&
                                          pembayaranBerikutnya !== null
                                        ? "whatsapp"
                                        : statusPembayaran === "ACTIVE"
                                        ? "lightwarning"
                                        : "lighterror"
                                }
                            >
                                {buttonText}
                            </Button>
                            {statusPembayaran === "ACTIVE" && (
                                <Button
                                    variant="outlined"
                                    disableElevation
                                    color="lightwarning"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                    href={`/payment/${orderId}/${layanan.id}/${id}/metode`}
                                >
                                    Pilih opsi pembayaran
                                </Button>
                            )}
                        </Stack>
                        {(alamatToko === "-" ||
                            !!!jenisUsaha ||
                            !!!alamatToko) && (
                            <Typography
                                variant="body2"
                                align="center"
                                color="red"
                                marginTop={1}
                            >
                                Segera lengkapi data UKM anda!
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </Badge>
            <ModalAlertPro
                isOpenModalAlertPro={isOpenModalAlertPro}
                closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                dataUkm={{ id: id, nama: namaToko }}
            />
        </>
    );
};

export default CardInfoToko;

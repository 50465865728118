import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    Box,
    Grid,
    TextField,
    useMediaQuery,
    Button,
    InputAdornment,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Autocomplete,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "../../services/axios";
import { LoadingButton } from "@mui/lab";
import { useUkmModal } from "../../context/ukmModalContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { EditUkmResponse, ModalUkmField } from "../../constants/types";
import { SketchPicker } from "react-color";
import { defaultColor } from "../../constants/common";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import useProvinsi from "../../services/queries/useProvinsi";
import useKota from "../../services/queries/useKota";
import { Add, Delete } from "@mui/icons-material";

interface IModalUkmProps {
    refetchUkm: () => void;
    refetchUser: () => void;
}

const schema = yup.object({
    namaToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    // noTelp: yup
    //     .string()
    //     .required("Kolom wajib diisi")
    //     // .matches(/^8[1-9][0-9]/, "No. Telepon tidak valid, contoh: 831xxxx")
    //     .min(9, "No. Telepon terlalu pendek")
    //     .max(13, "No. Telepon terlalu panjang"),
    alamatToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(500, "Alamat terlalu panjang"),
    gambarToko: yup.mixed(),
    // .test("fileType", "Gambar harus berformat jpg/jpeg/png", (value) =>
    //     ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
    // ),
    deskripsi: yup.string().max(100, "Deskripsi terlalu panjang"),
    besarUsaha: yup.string().required("Kolom wajib diisi"),
    jenisUsaha: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Jenis usaha terlalu panjang"),
    omset: yup.string().required("Kolom wajib diisi"),
    provinsi: yup
        .object()
        .shape({
            id: yup.string().required("Kolom wajib diisi"),
            nama: yup.string().required("Kolom wajib diisi"),
        })
        .required("Kolom wajib diisi"),
    kota: yup
        .object()
        .shape({
            id: yup.string().required("Kolom wajib diisi"),
            nama: yup.string().required("Kolom wajib diisi"),
            provinsiId: yup.string().required("Kolom wajib diisi"),
            provinsiNama: yup.string().required("Kolom wajib diisi"),
        })
        .required("Kolom wajib diisi"),
    noTelpArr: yup.array().of(
        yup.object().shape({
            noTelp: yup.string().required("Kolom wajib diisi"),
        }),
    ),
});

const ModalUkm = ({ refetchUkm, refetchUser }: IModalUkmProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isOpenModalUkm, closeModalUkm, dataUkm } = useUkmModal();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const { data: provinsiOptions, isLoading: isLoadingProvinsi } =
        useProvinsi();

    const [paramsKota, setParamsKota] = useState<number>();
    const { data: kotaOptions, isLoading: isLoadingKota } = useKota(paramsKota);

    const initialValues: ModalUkmField = useMemo(
        () => ({
            id: undefined,
            namaToko: "",
            noTelp: "",
            alamatToko: "",
            warna: defaultColor,
            gambarToko: null,
            deskripsi: "",
            besarUsaha: "",
            jenisUsaha: "",
            omset: "",
            noTelpArr: [{ noTelp: "" }],
        }),
        [],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        // getValues,
        reset,
        setError,
        clearErrors,
        watch,
    } = useForm<ModalUkmField>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "noTelpArr",
    });

    const addNoTelp = () => {
        const newNoTelp = {
            noTelp: "",
        };
        append(newNoTelp);
    };

    const watchAlamat = watch("alamatToko");
    const watchJenisUsaha = watch("jenisUsaha");
    const watchBesarUsaha = watch("besarUsaha");
    const watchOmset = watch("omset");

    const onSubmit = async (values: ModalUkmField) => {
        console.log(values);
        setIsButtonLoading(true);
        const formattedOmzet = values.omset.replace(/\./g, "");
        // const formattedNoTelp = "62" + values.noTelp;
        if (dataUkm) {
            try {
                const formData = new FormData();
                formData.append("namaToko", values.namaToko);
                formData.append("alamat", values.alamatToko);
                for (let i = 0; i < values.noTelpArr.length; i += 1) {
                    formData.append(`no_Telp[]`, values.noTelpArr[i].noTelp);
                }
                formData.append("warna", values.warna);
                formData.append("deskripsi", values.deskripsi ?? "");
                formData.append("omset", formattedOmzet);
                formData.append("besarUsaha", values.besarUsaha);
                formData.append("jenisUsaha", values.jenisUsaha);
                if (values.gambarToko) {
                    formData.append(
                        "gambar",
                        values.gambarToko ? (values.gambarToko as any) : "",
                    );
                }
                if (values.provinsi?.id) {
                    formData.append("provinsi", String(values.provinsi.id));
                }
                if (values.kota?.id) {
                    formData.append("kota", String(values.kota.id));
                }
                formData.append("_method", "put");

                const { data } = await axios.post<EditUkmResponse>(
                    `/api/ukm/${values.id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    // assignWords("Informasi ukm telah diubah");
                    // openAlert();
                    Swal.fire({
                        title: data.message ?? "Informasi ukm telah diubah",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    window.location.reload();
                    refetchUkm();
                    refetchUser();
                    // closeModalUkm();
                }
                setIsButtonLoading(false);
            } catch (error) {
                setIsButtonLoading(false);
                console.error(error);
            }
        } else {
            try {
                const { data } = await axios.post(`/api/item`, {});
                if (data.code === 200) {
                    // assignWords(
                    //     "UKM " + values.namaToko + " telah ditambahkan",
                    // );
                    // openAlert();
                    Swal.fire({
                        title: "UKM " + values.namaToko + " telah ditambahkan",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalUkm();
                }
                setIsButtonLoading(false);
                console.log(data);
            } catch (error) {
                setIsButtonLoading(false);
                console.error(error);
            }
        }
    };

    useEffect(() => console.log("dataUkm", dataUkm), [dataUkm]);

    useEffect(() => {
        if (isOpenModalUkm) {
            if (dataUkm) {
                reset({
                    ...dataUkm,
                    alamatToko:
                        dataUkm.alamatToko === "-" || !!!dataUkm.alamatToko
                            ? ""
                            : dataUkm.alamatToko,
                    besarUsaha: dataUkm.besarUsaha ?? "",
                    jenisUsaha: dataUkm.jenisUsaha ?? "",
                    omset:
                        dataUkm.omset === "null" || !!!dataUkm.omset
                            ? ""
                            : dataUkm.omset,
                });
                console.log(dataUkm);
            } else {
                reset(initialValues);
            }
        }
    }, [initialValues, isOpenModalUkm, dataUkm, reset]);
    // untuk debugging value field
    // const values = getValues();

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalUkm}
            onClose={closeModalUkm}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalUkm}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        {dataUkm ? "Ubah UKM" : "Tambah UKM"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {/* <code>{JSON.stringify(values)}</code> */}
                <Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                Nama UKM
                            </Typography>
                            <Controller
                                name="namaToko"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="namaToko"
                                        autoFocus
                                        placeholder="Contoh: UKM Budi Jaya"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaToko)}
                                        helperText={
                                            errors.namaToko
                                                ? errors.namaToko.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                No. Telepon
                            </Typography>
                            <Controller
                                name="noTelp"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="noTelp"
                                        placeholder="831xxxxxx"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.noTelp)}
                                        helperText={
                                            errors.noTelp
                                                ? errors.noTelp.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid> */}
                        {fields.map((field, idx) => (
                            <React.Fragment key={field.id}>
                                <Grid item xs={9}>
                                    <Controller
                                        name={`noTelpArr.${idx}.noTelp`}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label={`No Telepon ${idx + 1}`}
                                                variant="outlined"
                                                fullWidth
                                                error={Boolean(
                                                    fieldState.error,
                                                )}
                                                size="small"
                                                helperText={
                                                    fieldState.error
                                                        ? fieldState.error
                                                              .message
                                                        : ""
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                {idx === 0 ? null : (
                                    <Grid item xs={1}>
                                        <Button
                                            onClick={() => remove(idx)}
                                            size="small"
                                            color="buttonred"
                                        >
                                            <Delete />
                                        </Button>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                        <Grid item xs={9} justifyContent="center">
                            <Button
                                variant="outlined"
                                onClick={() => addNoTelp()}
                                startIcon={<Add />}
                                size="small"
                                color="buttongreen"
                                fullWidth
                            >
                                Tambah No. Telp
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body2" color="#808080">
                                Provinsi
                            </Typography>
                            <Controller
                                name="provinsi"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        loading={isLoadingProvinsi}
                                        options={provinsiOptions?.content ?? []}
                                        getOptionLabel={(option) => option.nama}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        value={field.value}
                                        size="small"
                                        onChange={(
                                            _event,
                                            newProvinsi: {
                                                id: number;
                                                nama: string;
                                            } | null,
                                        ) => {
                                            field.onChange(newProvinsi);
                                            if (newProvinsi) {
                                                setParamsKota(newProvinsi.id);
                                            } else {
                                                return;
                                            }
                                            setValue("kota", {
                                                id: 0,
                                                nama: "",
                                                provinsiId: 0,
                                                provinsiNama: "",
                                            });
                                        }}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Pilih Provinsi"
                                                error={!!fieldState?.error}
                                                helperText={
                                                    fieldState?.error
                                                        ? "Pilih satu provinsi"
                                                        : ""
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body2" color="#808080">
                                Kota
                            </Typography>
                            <Controller
                                name="kota"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        loading={isLoadingKota}
                                        options={kotaOptions?.content ?? []}
                                        getOptionLabel={(option) => option.nama}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        // inputValue={field.value?.nama ?? ""}
                                        value={field.value}
                                        size="small"
                                        onChange={(
                                            _event,
                                            newkota: {
                                                id: number;
                                                nama: string;
                                                provinsiId: number;
                                                provinsiNama: string;
                                            } | null,
                                        ) => {
                                            field.onChange(newkota);
                                        }}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Pilih Kota"
                                                error={!!fieldState?.error}
                                                helperText={
                                                    fieldState?.error
                                                        ? "Pilih satu kota"
                                                        : ""
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography variant="body2" color="#808080">
                                    Alamat
                                </Typography>
                                {!!!watchAlamat && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="alamatToko"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="alamatToko"
                                        placeholder="Contoh: Jalan Maju Jaya no. 93 Semarang"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        error={Boolean(errors.alamatToko)}
                                        helperText={
                                            errors.alamatToko
                                                ? errors.alamatToko.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                Deskripsi (opsional)
                            </Typography>
                            <Controller
                                name="deskripsi"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="deskripsi"
                                        placeholder="Contoh: UKM ini merupakan ukm yang menjual barang grosir, dan lain-lain"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        error={Boolean(errors.deskripsi)}
                                        helperText={
                                            errors.deskripsi
                                                ? errors.deskripsi.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography variant="body2" color="#808080">
                                    Jenis Usaha
                                </Typography>
                                {!!!watchJenisUsaha && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="jenisUsaha"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="jenisUsaha"
                                        placeholder="Contoh: Kuliner, Jasa, Perdagangan, Mebel, dan lain-lain"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors.jenisUsaha)}
                                        helperText={
                                            errors.jenisUsaha
                                                ? errors.jenisUsaha.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography variant="body2" color="#808080">
                                    Besar Usaha
                                </Typography>
                                {!!!watchBesarUsaha && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="besarUsaha"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl size="small" fullWidth>
                                        {/* <InputLabel>
                                                Pilih Besar Usaha
                                            </InputLabel> */}
                                        <Select
                                            margin="dense"
                                            onChange={onChange}
                                            value={value}
                                            // onChange={(
                                            //     event: SelectChangeEvent,
                                            // ) =>
                                            //     setBesarUsaha(
                                            //         event.target.value,
                                            //     )
                                            // }
                                            // value={besarUsaha}
                                            displayEmpty
                                            error={Boolean(errors.besarUsaha)}
                                        >
                                            <MenuItem value="">
                                                <em>-- Pilih Besar Usaha --</em>
                                            </MenuItem>
                                            <MenuItem value="Mikro">
                                                Mikro
                                            </MenuItem>
                                            <MenuItem value="Kecil">
                                                Kecil
                                            </MenuItem>
                                            <MenuItem value="Sedang">
                                                Sedang
                                            </MenuItem>
                                            <MenuItem value="Besar">
                                                Besar
                                            </MenuItem>
                                            <MenuItem value="Lainnya">
                                                Lainnya
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={Boolean(errors.besarUsaha)}
                                        >
                                            {errors.besarUsaha
                                                ? errors.besarUsaha.message
                                                : ""}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography variant="body2" color="#808080">
                                    Omzet Per Tahun
                                </Typography>
                                {!!!watchOmset && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="omset"
                                control={control}
                                render={({ field }) => (
                                    <NumberFormat
                                        customInput={TextField}
                                        id="omset"
                                        placeholder="24.000.000"
                                        size="small"
                                        // margin="dense"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        fullWidth
                                        variant="outlined"
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            const valueAkhir = value.replace(
                                                ".",
                                                "",
                                            );
                                            console.log(valueAkhir);
                                            setValue("omset", valueAkhir);
                                        }}
                                        error={Boolean(errors.omset)}
                                        helperText={
                                            errors.omset
                                                ? errors.omset.message
                                                : ""
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>Rp.</Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                Warna Utama
                            </Typography>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                marginTop={1}
                                marginBottom={2}
                            >
                                <Controller
                                    name="warna"
                                    control={control}
                                    render={({ field }) => (
                                        <Box>
                                            <Box
                                                onClick={() =>
                                                    setDisplayColorPicker(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                sx={{
                                                    width: "54px",
                                                    height: "54px",
                                                    borderRadius: "10px",
                                                    background: `${field.value}`,
                                                    cursor: "pointer",
                                                }}
                                            />
                                            {displayColorPicker ? (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        bottom: "170px",
                                                        left: "80px",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            position: "fixed",
                                                            top: "0px",
                                                            right: "0px",
                                                            bottom: "0px",
                                                            left: "0px",
                                                        }}
                                                        onClick={() =>
                                                            setDisplayColorPicker(
                                                                false,
                                                            )
                                                        }
                                                    />
                                                    <SketchPicker
                                                        color={field.value}
                                                        onChange={(color) =>
                                                            field.onChange(
                                                                color.hex,
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            ) : null}
                                        </Box>
                                    )}
                                />
                                <Typography
                                    variant="subtitle2"
                                    fontWeight={400}
                                    sx={{ maxWidth: "130px" }}
                                >
                                    Klik persegi untuk pilih warna
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2" color="#808080">
                                Logo (opsional)
                            </Typography>
                            <Box>
                                <Controller
                                    name="gambarToko"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="gambarToko"
                                            fullWidth
                                            type="file"
                                            variant="outlined"
                                            inputProps={{
                                                accept: "image/*",
                                            }}
                                            error={Boolean(errors.gambarToko)}
                                            helperText={
                                                errors.gambarToko
                                                    ? errors.gambarToko.message
                                                    : ""
                                            }
                                            {...field}
                                            onChange={(event: any) => {
                                                if (
                                                    event.target.files[0].size <
                                                    2097152
                                                ) {
                                                    console.log(
                                                        event.target.files[0],
                                                    );
                                                    setValue(
                                                        "gambarToko",
                                                        event.target.files[0],
                                                    );
                                                    clearErrors("gambarToko");
                                                } else {
                                                    event.target.value = "";
                                                    setError("gambarToko", {
                                                        type: "manual",
                                                        message:
                                                            "Ukuran gambar harus kurang dari 2MB",
                                                    });
                                                }
                                            }}
                                            value={undefined}
                                        />
                                    )}
                                />
                            </Box>
                            <Stack>
                                <Typography variant="caption" color="#6D6D6D">
                                    *Resolusi optimal: 300 x 300
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="#6D6D6D"
                                    marginBottom={2}
                                >
                                    *Ukuran maksimal: 2MB
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        spacing={isTabletScreen ? 0 : 2}
                        alignItems="center"
                    >
                        <Button
                            variant="outlined"
                            onClick={() => closeModalUkm()}
                            fullWidth={isTabletScreen ? false : true}
                            sx={{
                                display: { xs: "block", sm: "none" },
                            }}
                        >
                            <Typography>Kembali</Typography>
                        </Button>
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition="center"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            fullWidth={isTabletScreen ? false : true}
                        >
                            Simpan
                        </LoadingButton>
                    </Stack>
                </Fragment>
            </DialogContent>
        </Dialog>
    );
};

export default ModalUkm;

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import toRupiah from "../../utils/toRupiah";
import useDetailInvoice from "../../services/queries/useDetailInvoice";
import ModalDetailInvoice from "../ModalDetailInvoice/ModalDetailInvoice";
import ModalRevertInvoice from "../ModalRevertInvoice/ModalRevertInvoice";
import Button from "@mui/material/Button";

interface ICardInvoiceProps {
    detailInvoiceId: number;
    noInvoice: string;
    tanggalInvoice: string;
    hargaPenjualan: number;
    isReverted: number;
    metode: string;
    isPiutang: number;
    dp?: number | null;
    jatuhTempo: string | null;
    refetchListInvoice: () => void;
}

const CardInvoice = ({
    detailInvoiceId,
    noInvoice,
    tanggalInvoice,
    hargaPenjualan,
    isReverted,
    metode,
    jatuhTempo,
    dp,
    refetchListInvoice,
    isPiutang,
}: ICardInvoiceProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const [isOpenModalRevertInvoice, setIsOpenModalRevertInvoice] =
        useState(false);
    const [isOpenModalDetailInvoice, setIsOpenModalDetailInvoice] =
        useState(false);
    const { data: detailInvoice, status: statusDetailInvoice } =
        useDetailInvoice(detailInvoiceId);

    const closeModalDetailInvoice = () => {
        setIsOpenModalDetailInvoice(false);
    };

    return (
        <React.Fragment>
            <Card
                variant="outlined"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                    padding: isTabletScreen ? 1 : 2,
                }}
            >
                <Box>
                    <Typography fontWeight="bold">{noInvoice}</Typography>
                    <Typography fontSize={12} color="#76747C">
                        {tanggalInvoice}
                    </Typography>
                    <Typography
                        marginTop={1}
                        fontSize={12}
                        fontWeight="bold"
                        color="#76747C"
                    >
                        {isReverted
                            ? "Pembayaran dibatalkan"
                            : hargaPenjualan < 0
                            ? "Laporan pembatalan"
                            : `${isPiutang ? "Piutang" : metode}: ${toRupiah(
                                  hargaPenjualan,
                              )}`}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsOpenModalDetailInvoice(true);
                        }}
                        size="small"
                        color="buttongreen"
                        disableElevation
                        sx={{
                            minWidth: "unset",
                            padding: "8px",
                            width: "32px",
                            height: "32px",
                        }}
                    >
                        <VisibilityIcon
                            sx={{ width: "16px", height: "16px" }}
                        />
                    </Button>
                    {/* {hargaPenjualan > 0 && isReverted === 0 && (
                        <IconButton
                            onClick={() => {
                                setIsOpenModalRevertInvoice(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )} */}
                </Stack>
            </Card>
            {detailInvoice && (
                <ModalDetailInvoice
                    jatuhTempo={jatuhTempo}
                    fullScreen={fullScreen}
                    isOpenModalDetailInvoice={isOpenModalDetailInvoice}
                    statusDetailInvoice={statusDetailInvoice}
                    closeModalDetailInvoice={closeModalDetailInvoice}
                    dp={Number(dp)}
                    isPiutang={isPiutang}
                    data={detailInvoice}
                />
            )}
            <ModalRevertInvoice
                diskon={Number(detailInvoice?.diskon)}
                totalHarga={Number(detailInvoice?.totalHarga)}
                totalTagihan={Number(detailInvoice?.totalPembayaran)}
                uangDibayar={Number(detailInvoice?.uangYangDibayar)}
                kembalian={Number(detailInvoice?.kembalian)}
                noInvoice={String(detailInvoice?.nomorInvoice)}
                refetchLaporan={() => {}}
                refetchListInvoice={refetchListInvoice}
                refetchProdukTerjual={() => {}}
                refetchMutasiStok={() => {}}
                refetchGrafikPenjualan={() => {}}
                item={detailInvoice?.daftarItem}
                statusItem={statusDetailInvoice}
                invoiceId={detailInvoiceId}
                isOpenModalRevertInvoice={isOpenModalRevertInvoice}
                closeModalRevertInvoice={() =>
                    setIsOpenModalRevertInvoice(false)
                }
            />
        </React.Fragment>
    );
};

export default CardInvoice;

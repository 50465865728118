import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTheme } from "@mui/material/styles";
import CardPesan from "../CardPesan/CardPesan";
import ReplayIcon from "@mui/icons-material/Replay";
import useItem from "../../services/queries/useItem";
import toRupiah from "../../utils/toRupiah";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { IOptions, ParamsGetItem } from "../../constants/types";
import { ReactComponent as BelumAdaPesanan } from "../../assets/images/BelumAdaPesanan.svg";
import Swal from "sweetalert2";
import useListInvoice from "../../services/queries/useListInvoice";
import CardInvoice from "../CardInvoice/CardInvoice";
import ToggleButton from "@mui/material/ToggleButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useUkmIsDueExpired from "../../services/queries/useUkmIsDueExpired";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { Checkbox, FormControlLabel } from "@mui/material";
import ModalBayar from "../ModalBayar/ModalBayar";
import ModalKonfirmasiBayar from "../ModalKonfirmasiBayar/ModalKonfirmasiBayar";

interface IListPesanProps {}

enum SectionPesan {
    Keranjang = "SATUAN",
    Invoice = "INVOICE",
}

const ListPesan = (props: IListPesanProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const [isScanBarcode, setIsScanBarcode] = useState<boolean>(false);
    const { ukmIdUser } = useAuth();
    const {
        cart,
        totalHarga,
        addToCart,
        openModalBayar,
        handleReset,
        maxItem,
        isKeterangan,
        handleIsKeterangan,
        isFetchingItems,
        cancelFetchingItems,
        // keteranganBeli,
        // handleChangeKeteranganBeli,
    } = useTransaksi();

    const [diskon, setDiskon] = useState<number>();
    const [persentaseDiskon, setPersentaseDiskon] = useState<number | null>();
    const [pajak, setPajak] = useState<number>();
    const [persentasePajak, setPersentasePajak] = useState<number | null>();
    const [tagihan, setTagihan] = useState<number>();
    const [uangDibayar, setUangDibayar] = useState<number>();
    const [kembalian, setKembalian] = useState<number>();
    const [sales, setSales] = useState<IOptions | null>(null);

    const [sku, setSku] = useState<string>("");
    const [inputSku, setInputSku] = useState<string>("");
    const [section, setSection] = useState<SectionPesan>(
        SectionPesan.Keranjang,
    );

    const initialParamsItem = {
        ukmId: undefined,
        size: 1000,
        isShow: 0,
        page: 1,
        search: "",
        kategori: "",
    };
    const [paramsItem, setParamsItem] =
        useState<ParamsGetItem>(initialParamsItem);
    const { data: item, refetch: refetchItem } = useItem(paramsItem);

    const moment = require("moment");
    let now = moment().format("YYYY/MM/DD");

    const initialParamsListInvoice = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 100,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
        kasir: "",
        pelanggan: "",
        sales: "",
        metodeId: "",
        voidInvoice: "",
        ar: 0,
    };

    const { data: listInvoice, refetch: refetchListInvoice } = useListInvoice(
        initialParamsListInvoice,
    );

    const initialParamsUkmIsDueExpired = React.useMemo(
        () => ({
            search: "",
            size: 50,
            page: 1,
            isDueExpired: 1,
        }),
        [],
    );
    const { data: ukmIsDueExpired } = useUkmIsDueExpired(
        initialParamsUkmIsDueExpired,
    );

    const { data: user } = useUser();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const [keteranganBeli, setKeteranganBeli] = useState<string>("");
    const [keteranganBeli2, setKeteranganBeli2] = useState<string>("");

    const resetKeteranganBeli = useCallback(() => {
        setKeteranganBeli("");
        setKeteranganBeli2("");
    }, []);

    const handleChangeKeteranganBeli = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setKeteranganBeli(event.target.value);
    };

    const handleChangeKeteranganBeli2 = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setKeteranganBeli2(event.target.value);
    };

    const handlingRincianPembayaran = (
        persentaseDiskon: number | null,
        diskon: number | null,
        persentasePajak: number | null,
        pajak: number | null,
        tagihan: number,
        uangYangDibayar: number,
        kembalian: number,
        sales: IOptions | null,
    ) => {
        if (diskon && diskon > 0) {
            setDiskon(diskon);
        } else {
            setDiskon(0);
        }
        if (persentaseDiskon !== null) {
            setPersentaseDiskon(persentaseDiskon);
        }
        if (pajak && pajak > 0) {
            setPajak(pajak);
        } else {
            setPajak(0);
        }
        if (persentasePajak !== null) {
            setPersentasePajak(persentasePajak);
        }
        setTagihan(tagihan);
        setUangDibayar(uangYangDibayar);
        setKembalian(kembalian);
        setSales(sales);
    };

    useEffect(() => {
        if (isFetchingItems) {
            refetchListInvoice();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchListInvoice]);

    useEffect(() => {
        if (isScanBarcode) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [isScanBarcode, ukmIdUser]);

    useEffect(() => {
        if (inputSku !== "") {
            setSku("");
            setInputSku("");
            const selectedProduct = item?.content.filter(
                (list) => list.sku === sku,
            )[0];
            const index = cart.findIndex((c) => {
                return c.id === selectedProduct?.id;
            });

            if (
                (selectedProduct && selectedProduct?.stok > 0) ||
                selectedProduct?.stok === null
            ) {
                addToCart({
                    id: Number(selectedProduct?.id),
                    namaBarang: String(selectedProduct?.namaBarang),
                    sku: String(selectedProduct?.sku),
                    harga: Number(selectedProduct?.harga),
                    url: String(selectedProduct?.gambar),
                    jumlahStok: selectedProduct?.stok ?? null,
                    qty: 1,
                    detailBundling: selectedProduct.detailBundling,
                    hpp: selectedProduct.hpp,
                    faktur: [],
                    multipleHarga: selectedProduct.hargaMultiple.map((li) => ({
                        id: li.id,
                        harga: Number(li.harga),
                        keterangan: li.keterangan,
                    })),
                });
                // if (cart.length <= 0) {
                //     addToCart({
                //         id: Number(selectedProduct?.id),
                //         namaBarang: String(selectedProduct?.namaBarang),
                //         sku: String(selectedProduct?.sku),
                //         harga: Number(selectedProduct?.harga),
                //         url: String(selectedProduct?.gambar),
                //         jumlahStok: selectedProduct?.stok ?? null,
                //         qty: 1,
                //         detailBundling: selectedProduct.detailBundling,
                //     });
                // } else if (cart.length > 0) {
                //     if (
                //         (selectedProduct &&
                //             selectedProduct?.stok > cart[index]?.qty) ||
                //         selectedProduct?.stok === null
                //     ) {
                //         addToCart({
                //             id: Number(selectedProduct?.id),
                //             namaBarang: String(selectedProduct?.namaBarang),
                //             sku: String(selectedProduct?.sku),
                //             harga: Number(selectedProduct?.harga),
                //             url: String(selectedProduct?.gambar),
                //             jumlahStok: selectedProduct?.stok ?? null,
                //             qty: 1,
                //             detailBundling: selectedProduct.detailBundling,
                //         });
                //     } else {
                //         Swal.fire({
                //             title: "Stok Barang tidak mencukupi",
                //             position: "top-end",
                //             showConfirmButton: false,
                //             icon: "error",
                //             toast: true,
                //             timer: 3000,
                //             timerProgressBar: true,
                //             showCloseButton: true,
                //         });
                //     }
                // }
            } else if (selectedProduct && selectedProduct?.stok <= 0) {
                Swal.fire({
                    title: "Barang telah habis",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            } else {
                Swal.fire({
                    title: "Barang tidak ditemukan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
        }
    }, [addToCart, cart, inputSku, item?.content, sku, ukmIdUser]);

    return (
        <React.Fragment>
            <Box
                display="grid"
                gridTemplateRows="auto auto 1fr auto auto"
                sx={{
                    height: isPhoneScreen
                        ? ukmData &&
                          ukmIsDueExpired &&
                          ukmIsDueExpired.totalElements > 0 &&
                          ukmIsDueExpired.content.filter(
                              (list) => list.id === ukmData.id,
                          ).length > 0
                            ? "calc(100vh - 98px)"
                            : "calc(100vh - 56px)"
                        : isLaptopScreen
                        ? ukmData &&
                          ukmIsDueExpired &&
                          ukmIsDueExpired.totalElements > 0 &&
                          ukmIsDueExpired.content.filter(
                              (list) => list.id === ukmData.id,
                          ).length > 0
                            ? "calc(100vh - 48.75px)"
                            : "100vh"
                        : "calc(100vh - 64px)",
                }}
            >
                <Box paddingTop={2} paddingX={2}>
                    <ToggleButtonGroup
                        color="primary"
                        value={section}
                        exclusive
                        size="small"
                        onChange={(_, value) => {
                            if (value !== null) {
                                setSection(value);
                            }
                        }}
                        fullWidth
                    >
                        <ToggleButton value={SectionPesan.Keranjang}>
                            Keranjang
                        </ToggleButton>
                        <ToggleButton value={SectionPesan.Invoice}>
                            Daftar Transaksi
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {section === SectionPesan.Keranjang && (
                    <React.Fragment>
                        <Box>
                            <Box
                                display="grid"
                                gridTemplateColumns="1fr auto"
                                marginX={2}
                                marginY={1}
                                gap={2}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography
                                    variant={isTabletScreen ? "body2" : "h6"}
                                    fontSize={
                                        isPhoneScreen ? "18px" : undefined
                                    }
                                    fontWeight="bold"
                                >
                                    Barang yang Dipesan
                                </Typography>
                                {/* <Button
                        variant="contained"
                        onClick={() => setIsScanBarcode(!isScanBarcode)}
                        startIcon={<IoBarcodeOutline size={32} />}
                        size="medium"
                    >
                        Mode Barcode
                    </Button> */}
                                <Stack
                                    direction="column"
                                    spacing={0}
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="body2"
                                        fontSize="0.75rem"
                                        fontWeight="bold"
                                    >
                                        Mode Barcode
                                    </Typography>
                                    <ClickAwayListener
                                        onClickAway={() =>
                                            setIsScanBarcode(false)
                                        }
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={0}
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="body2"
                                                fontSize="0.75rem"
                                            >
                                                Off
                                            </Typography>
                                            <Switch
                                                checked={isScanBarcode}
                                                size="small"
                                                onChange={() =>
                                                    setIsScanBarcode(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                            />
                                            <Typography
                                                variant="body2"
                                                fontSize="0.75rem"
                                            >
                                                On
                                            </Typography>
                                        </Stack>
                                    </ClickAwayListener>
                                </Stack>
                                {/* <Link
                        onClick={() => setIsScanBarcode(!isScanBarcode)}
                        sx={{ display: "flex", cursor: "pointer" }}
                    >
                        <IoBarcodeOutline size={32} />
                    </Link> */}
                            </Box>
                            {isScanBarcode && (
                                <Stack
                                    marginLeft={2}
                                    marginBottom={3}
                                    spacing={2}
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant={
                                            isTabletScreen || isPhoneScreen
                                                ? "caption"
                                                : "subtitle2"
                                        }
                                    >
                                        Input SKU (Scan)
                                    </Typography>
                                    <TextField
                                        value={sku}
                                        size="small"
                                        autoFocus={isScanBarcode === true}
                                        onChange={(e) => setSku(e.target.value)}
                                        onKeyPress={(e) => {
                                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                            e.key === "Enter"
                                                ? setInputSku(sku)
                                                : undefined;
                                        }}
                                        sx={{
                                            width: isPhoneScreen
                                                ? undefined
                                                : 200,
                                        }}
                                        inputProps={{
                                            style: {
                                                height: 20,
                                            },
                                        }}
                                    />
                                </Stack>
                            )}
                        </Box>
                        <Box
                            className="hide-scroll"
                            marginX={2}
                            sx={{
                                overflowY: "auto",
                            }}
                        >
                            {cart.length > 0 ? (
                                cart.map((rows, idx) => (
                                    <CardPesan
                                        key={idx.toString()}
                                        id={rows.id}
                                        namaBarang={rows.namaBarang}
                                        sku={String(rows.sku)}
                                        harga={rows.harga}
                                        jumlahStok={rows.jumlahStok}
                                        url={rows.url}
                                        qty={rows.qty}
                                        detailBundling={rows.detailBundling}
                                        hpp={rows.hpp}
                                        multipleHarga={rows.multipleHarga}
                                    />
                                ))
                            ) : (
                                <Box
                                    display="flex"
                                    flex={1}
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%"
                                >
                                    <BelumAdaPesanan
                                        width={isTabletScreen ? "20%" : "50%"}
                                        style={{ height: "fit-content" }}
                                    />
                                    <Typography
                                        marginTop={
                                            isTabletScreen
                                                ? 2
                                                : isPhoneScreen
                                                ? 1
                                                : 5
                                        }
                                        variant={
                                            isTabletScreen || isPhoneScreen
                                                ? "body2"
                                                : "h6"
                                        }
                                        fontWeight="bold"
                                    >
                                        Belum ada pesanan
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Divider />
                        <Box paddingX={2} paddingY={isTabletScreen ? 1 : 2}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    variant={
                                        isTabletScreen ? "body2" : "subtitle1"
                                    }
                                    color="#76747C"
                                    fontWeight={600}
                                >
                                    Subtotal Harga
                                </Typography>
                                <Typography
                                    variant={isTabletScreen ? "body2" : "h6"}
                                    fontWeight="bold"
                                >
                                    {toRupiah(totalHarga)}
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            width="100%"
                            paddingX={2}
                            paddingBottom={isTabletScreen ? 1 : 2}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isKeterangan}
                                        size={
                                            isTabletScreen ? "small" : undefined
                                        }
                                        onChange={(_, checked) => {
                                            handleIsKeterangan(checked);
                                        }}
                                    />
                                }
                                label="Tambah Keterangan"
                                componentsProps={{
                                    typography: {
                                        fontSize: isTabletScreen
                                            ? "12px"
                                            : undefined,
                                    },
                                }}
                                sx={{
                                    height: "fit-content",
                                }}
                            />
                            {isKeterangan && (
                                <>
                                    <TextField
                                        label="Keterangan"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size={
                                            isTabletScreen ? "small" : undefined
                                        }
                                        value={keteranganBeli}
                                        onChange={handleChangeKeteranganBeli}
                                        helperText={`${keteranganBeli?.length}/100`}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                    <TextField
                                        label="Keterangan 2"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size={
                                            isTabletScreen ? "small" : undefined
                                        }
                                        value={keteranganBeli2}
                                        onChange={handleChangeKeteranganBeli2}
                                        helperText={`${keteranganBeli2?.length}/100`}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </>
                            )}
                        </Box>
                        <Box marginX={2} marginBottom={isTabletScreen ? 1 : 2}>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => handleReset()}
                                    startIcon={<ReplayIcon />}
                                    size={isTabletScreen ? "small" : "large"}
                                    fullWidth
                                >
                                    Kosongkan
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => openModalBayar()}
                                    size={isTabletScreen ? "small" : "large"}
                                    fullWidth
                                    disabled={cart.length === 0}
                                >
                                    Bayar
                                </Button>
                            </Stack>
                        </Box>
                    </React.Fragment>
                )}

                {section === SectionPesan.Invoice && (
                    <React.Fragment>
                        <Typography
                            variant={isTabletScreen ? "body2" : "h6"}
                            fontSize={isPhoneScreen ? "18px" : undefined}
                            fontWeight="bold"
                            marginX={2}
                            marginY={3}
                        >
                            Daftar Transaksi
                        </Typography>
                        <Box
                            className="hide-scroll"
                            marginX={2}
                            sx={{
                                overflowY: "scroll",
                            }}
                        >
                            {listInvoice && listInvoice.totalElements > 0 ? (
                                listInvoice.content.map((li) => (
                                    <CardInvoice
                                        isPiutang={li.isPiutang}
                                        jatuhTempo={li.jatuhTempo}
                                        dp={li.dp}
                                        key={li.id}
                                        detailInvoiceId={li.id}
                                        noInvoice={li.nomorInvoice}
                                        tanggalInvoice={li.tanggal}
                                        hargaPenjualan={li.uangYangDibayar}
                                        isReverted={li.isReverted}
                                        refetchListInvoice={refetchListInvoice}
                                        metode={li.metode ?? "Tunai"}
                                    />
                                ))
                            ) : (
                                <Box
                                    display="flex"
                                    flex={1}
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%"
                                >
                                    <BelumAdaPesanan
                                        width={isTabletScreen ? "20%" : "50%"}
                                        style={{ height: "fit-content" }}
                                    />
                                    <Typography
                                        marginTop={
                                            isTabletScreen
                                                ? 2
                                                : isPhoneScreen
                                                ? 1
                                                : 5
                                        }
                                        variant={
                                            isTabletScreen || isPhoneScreen
                                                ? "body2"
                                                : "h6"
                                        }
                                        fontWeight="bold"
                                    >
                                        Belum ada transaksi
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Divider />
                        <Box paddingX={2} paddingY={isTabletScreen ? 1 : 2}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    variant={
                                        isTabletScreen ? "body2" : "subtitle1"
                                    }
                                    color="#76747C"
                                    fontWeight={600}
                                >
                                    Subtotal Harga
                                </Typography>
                                <Typography
                                    variant={isTabletScreen ? "body2" : "h6"}
                                    fontWeight="bold"
                                >
                                    {toRupiah(totalHarga)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            width="100%"
                            paddingX={2}
                            paddingBottom={isTabletScreen ? 1 : 2}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isKeterangan}
                                        size={
                                            isTabletScreen ? "small" : undefined
                                        }
                                        onChange={(_, checked) => {
                                            handleIsKeterangan(checked);
                                        }}
                                    />
                                }
                                label="Tambah Keterangan"
                                componentsProps={{
                                    typography: {
                                        fontSize: isTabletScreen
                                            ? "12px"
                                            : undefined,
                                    },
                                }}
                                sx={{
                                    height: "fit-content",
                                }}
                            />
                            {isKeterangan && (
                                <>
                                    <TextField
                                        label="Keterangan"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size={
                                            isTabletScreen ? "small" : undefined
                                        }
                                        value={keteranganBeli}
                                        onChange={handleChangeKeteranganBeli}
                                        helperText={`${keteranganBeli?.length}/100`}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                    <TextField
                                        label="Keterangan 2"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size={
                                            isTabletScreen ? "small" : undefined
                                        }
                                        value={keteranganBeli2}
                                        onChange={handleChangeKeteranganBeli2}
                                        helperText={`${keteranganBeli2?.length}/100`}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </>
                            )}
                        </Box>
                        <Box marginX={2} marginBottom={isTabletScreen ? 1 : 2}>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => handleReset()}
                                    startIcon={<ReplayIcon />}
                                    size={isTabletScreen ? "small" : "large"}
                                    fullWidth
                                >
                                    Kosongkan
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => openModalBayar()}
                                    size={isTabletScreen ? "small" : "large"}
                                    fullWidth
                                    disabled={cart.length === 0}
                                >
                                    Bayar
                                </Button>
                            </Stack>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
            <ModalBayar handlingRincianPembayaran={handlingRincianPembayaran} />
            <ModalKonfirmasiBayar
                kasir={String(user?.namaLengkap)}
                sales={sales}
                diskon={diskon}
                persentaseDiskon={persentaseDiskon}
                pajak={pajak}
                persentasePajak={persentasePajak}
                totalTagihan={Number(tagihan)}
                uangDibayar={Number(uangDibayar)}
                kembalian={Number(kembalian)}
                refetch={refetchItem}
                keteranganBeli={keteranganBeli}
                resetKeteranganBeli={resetKeteranganBeli}
                keteranganBeli2={keteranganBeli2}
            />
        </React.Fragment>
    );
};

export default ListPesan;

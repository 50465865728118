import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BayarARSatuanValues, DataAR } from "../../constants/types";
import moment from "moment";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import NumberFormat from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import toRibuahDesimal from "../../utils/toRibuanDesimal";
import toRupiahDesimal from "../../utils/toRupiahDesimal";
import { useTransaksi } from "../../context/transaksiContext";

registerLocale("id", id);
setDefaultLocale("id");

interface IModalBayarARSatuanProps {
    refetchListInvoice: () => void;
    isOpenModalBayarARSatuan: boolean;
    closeModalBayarARSatuan: () => void;
    dataAR: DataAR;
}

const ModalBayarPiutang = ({
    isOpenModalBayarARSatuan,
    closeModalBayarARSatuan,
    dataAR,
    refetchListInvoice,
}: IModalBayarARSatuanProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();

    const initialValues: BayarARSatuanValues = useMemo(
        () => ({
            transaksiId: dataAR.id,
            nominal: toRibuahDesimal(String(dataAR.sisaHutang)),
            totalNominal: dataAR.sisaHutang,
            tanggal: new Date(),
            jatuhTempo: moment(dataAR.jatuhTempo).toDate(),
        }),
        [dataAR.id, dataAR.jatuhTempo, dataAR.sisaHutang],
    );

    const { control, setValue, handleSubmit, reset } =
        useForm<BayarARSatuanValues>({
            defaultValues: initialValues,
        });

    const onSubmit = async (values: BayarARSatuanValues) => {
        setIsButtonLoading(true);
        try {
            const body = {
                transaksiId: values.transaksiId,
                nominal: String(values.totalNominal),
                tanggal: values.tanggal
                    ? moment(values.tanggal).format("YYYY-MM-DD")
                    : null,
            };
            console.log(body);
            const { data } = await axios.post(`/api/pembayaran-ar`, body);
            if (data.code === 200) {
                refetchListInvoice();
                Swal.fire({
                    title: "Pembayaran Piutang diterima",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                setFetchingItems();
                closeModalBayarARSatuan();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            console.error(error);
        }
    };

    const onUpdate = async (values: BayarARSatuanValues) => {
        setIsButtonLoading(true);
        try {
            const body = {
                transaksiId: values.transaksiId,
                jatuhTempo: values.jatuhTempo
                    ? moment(values.jatuhTempo).format("YYYY-MM-DD")
                    : null,
            };
            console.log(body);
            const { data } = await axios.post(`/api/update-jt`, body);
            if (data.code === 200) {
                refetchListInvoice();
                Swal.fire({
                    title: "Jatuh tempo berhasil diupdate",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                closeModalBayarARSatuan();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (isOpenModalBayarARSatuan) {
            reset(initialValues);
        }
    }, [initialValues, isOpenModalBayarARSatuan, reset]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalBayarARSatuan}
            onClose={() => {
                closeModalBayarARSatuan();
            }}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalBayarARSatuan}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Terima Pembayaran Piutang
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: 6 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontWeight="bold">
                            {dataAR.noInvoice}
                        </Typography>
                        <Typography component="div">
                            Pelanggan:{" "}
                            <Typography display="inline" fontWeight={500}>
                                {dataAR.pelanggan.nama
                                    ? dataAR.pelanggan.nama
                                    : "-"}
                            </Typography>
                        </Typography>
                        <Typography component="div">
                            Sisa Tagihan:{" "}
                            <Typography display="inline" fontWeight={500}>
                                {toRupiahDesimal(dataAR.sisaHutang)}
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="flex-start"
                    spacing={1.5}
                    marginTop={1}
                >
                    <Grid item xs={12}>
                        <Typography fontWeight={500} component="div">
                            Jatuh Tempo:
                        </Typography>
                        <Grid container spacing={1.5} alignItems={"center"}>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    name="jatuhTempo"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            className="range-filter"
                                            dateFormat="dd/MM/yyyy"
                                            popperPlacement="bottom-start"
                                            disabledKeyboardNavigation
                                            onFocus={(e) => e.target.blur()}
                                            startDate={field.value}
                                            onChange={(update) => {
                                                field.onChange(update);
                                            }}
                                            monthsShown={1}
                                            customInput={
                                                <Stack
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    direction="row"
                                                    // width="100%"
                                                    bgcolor="#FFFF"
                                                    height="40px"
                                                    sx={{
                                                        border: "1px solid #cbcbcb",
                                                        borderRadius: "4px",
                                                        alignItems: "center",
                                                        padding: 2,
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Typography>
                                                        {field.value?.toLocaleString(
                                                            "id",
                                                            {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "numeric",
                                                            },
                                                        )}
                                                    </Typography>
                                                    <ArrowDropDown
                                                        sx={{
                                                            marginRight: "-9px",
                                                        }}
                                                    />
                                                </Stack>
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <LoadingButton
                                    loading={isButtonLoading}
                                    loadingPosition="center"
                                    variant="outlined"
                                    onClick={handleSubmit(onUpdate)}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography fontWeight={500} component="div">
                            Nominal Bayar:
                        </Typography>
                        <Controller
                            name="nominal"
                            control={control}
                            rules={{
                                required: "Kolom wajib diisi",
                                min: {
                                    value: 0,
                                    message: "Nominal bayar tidak boleh < 0", // JS only: <p>error message</p> TS only support string
                                },
                                max: {
                                    value: dataAR.sisaHutang,
                                    message:
                                        "Nominal bayar tidak boleh > tagihan", // JS only: <p>error message</p> TS only support string
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <NumberFormat
                                    customInput={TextField}
                                    placeholder="0"
                                    // margin="dense"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error
                                            ? fieldState?.error.message
                                            : ""
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>Rp.</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onValueChange={(v) => {
                                        field.onChange(Number(v.value));
                                        const totalNominal = Number(v.value);
                                        setValue("totalNominal", totalNominal);
                                    }}
                                    value={field.value}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography fontWeight={500} component="div">
                            Tanggal Bayar:
                        </Typography>
                        <Controller
                            name="tanggal"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className="range-filter"
                                    dateFormat="dd/MM/yyyy"
                                    popperPlacement="bottom-start"
                                    disabledKeyboardNavigation
                                    onFocus={(e) => e.target.blur()}
                                    startDate={field.value}
                                    onChange={(update) => {
                                        field.onChange(update);
                                    }}
                                    monthsShown={1}
                                    customInput={
                                        <Stack
                                            display="flex"
                                            justifyContent="space-between"
                                            direction="row"
                                            // width="100%"
                                            bgcolor="#FFFF"
                                            height="40px"
                                            sx={{
                                                border: "1px solid #cbcbcb",
                                                borderRadius: "4px",
                                                alignItems: "center",
                                                padding: 2,
                                                cursor: "pointer",
                                            }}
                                        >
                                            <Typography>
                                                {field.value?.toLocaleString(
                                                    "id",
                                                    {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                    },
                                                )}
                                            </Typography>
                                            <ArrowDropDown
                                                sx={{
                                                    marginRight: "-9px",
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2} marginTop={1}>
                    <Grid item xs={12}>
                        <Typography fontWeight={500} component="div">
                            Total:
                        </Typography>
                        <Typography component="div">
                            {toRupiahDesimal(Number(watchTotalNominal))}
                        </Typography>
                    </Grid>
                </Grid> */}

                <Box
                    mt={8}
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr"
                    justifyContent="flex-end"
                    columnGap="16px"
                >
                    <div />
                    <div />
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Bayar
                    </LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ModalBayarPiutang;
